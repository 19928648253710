import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../modules/Typography';
import TextArea from '../modules/TextArea';

const SubmissionTextQuestion = ({ question, handleQuestion, error }) => {
    const [answer, setAnswer] = useState(question.answer || '');

    const handleBlur = () => {
        handleQuestion({ id: question.id, value: answer });
    };

    const handleChange = e => {
        const answer = e.target.value;
        setAnswer(answer);
    };

    return (
        <div className='Submission__Question'>
            <Heading number={4}>{question.question}</Heading>
            <TextArea
                placeholder='Type here...'
                value={answer}
                onChange={handleChange}
                className='Submission__Question__TextArea'
                onBlur={handleBlur}
                error={error}
                errorMessage='Please provide an answer to this question.'
            />
        </div>
    );
};

SubmissionTextQuestion.propTypes = {
    handleQuestion: PropTypes.func,
    error: PropTypes.bool,
    question: PropTypes.object
};

export default SubmissionTextQuestion;
