import React from 'react';
import PropTypes from 'prop-types';

const CircleIcon = props => {
    const getCircleText = (rec) => {
        switch (true) {
        case Number.isFinite(rec):
            return rec;
        case rec === true:
            return 'Y';
        case rec === false:
            return 'N';
        default:
            return 'N/A';
        }
    };

    const getCircleColor = (avg) => {
        if (avg === null || avg === 'N/A') {
            avg = -1;
        }
        if (avg === true) {
            avg = 5;
        }
        if (avg === false) {
            avg = 0;
        }
        switch (Math.floor(avg)) {
        case 0:
            return 'deepred';
        case 1:
            return 'red';
        case 2:
            return 'orange';
        case 3:
            return 'lightorange';
        case 4:
            return 'lightgreen';
        case 5:
            return 'green';
        default:
            return 'gray';
        }
    };

    return (
        <div className={`CircleIcon ${props.className} CircleIcon--${getCircleColor(props.text)} flex center`}>
            <div className='CircleIcon__text'>
                {getCircleText(props.text)}
            </div>
        </div>
    );
};

export default CircleIcon;

CircleIcon.propTypes = {
    /* Element classname */
    className: PropTypes.string,

    /* Text to display inside the circle icon */
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
};

CircleIcon.defaultProps = {
    className: '',
    text: 'N/A'
};
