import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import fetch from '../../utils/fetch';
import Icon from './Icon';
import { Body } from './Typography';
const DownloadFile = props => {
    const handleDownload = async () => {
        try {
            const response = await fetch(`/api/download?fileUrl=${encodeURIComponent(props.url)}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            saveAs(response, props.filename);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    return (
        <div onClick={handleDownload} className='pointer'>
            <Icon name='download-b' className='download-icon' />
            <Body color='primary-light'>{props.filename}</Body>
        </div>
    );
};

DownloadFile.propTypes = {
    url: PropTypes.string,
    filename: PropTypes.string
};

export default DownloadFile;
