import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const DialogFooter = (props) => (
    <div className='DialogWrapper__box__footer'>
        {props.onCancel && <Button type='secondary-outline' text={props.confirmCancelText} onClick={props.onCancel} />}
        {props.onConfirm && <Button loading={props.loading} type='primary' text={props.confirmText} onClick={props.onConfirm} disabled={props.disabled} />}
    </div>
);

export default DialogFooter;

DialogFooter.propTypes = {
    // whether or not the confirm button is disabled
    disabled: PropTypes.bool,

    // function to cancel or close the dialog
    onCancel: PropTypes.func,

    // text of the cancel button
    confirmCancelText: PropTypes.string,

    // function to confirm the dialog
    onConfirm: PropTypes.func,

    // text of the confirm button
    confirmText: PropTypes.string,

    // whether or not the confirm action is loading and should be disabled
    loading: PropTypes.bool
};

DialogFooter.defaultProps = {
    confirmCancelText: 'Cancel',
    confirmText: 'OK',
    disabled: false,
    loading: false
};
