import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../modules/Typography';
import { SubmissionContext } from '../pages/Submission';
import { Row, Col } from '../modules/Grid';
import IconButton from '../modules/IconButton';
import DownloadFile from '../modules/DownloadFile';

const SubmissionLegal = props => {
    const { allExpanded } = useContext(SubmissionContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    const apps = props.applicants.map(applicant => (
        {
            name: `${applicant.firstName} ${applicant.lastName}`,
            agreement: (applicant.uploads.find(upload => upload.name === 'agreement')) || null
        }));

    return (
        <div className='Submission__Applicant'>
            <Row>
                <Col xs={12} className='flex'>
                    <Heading number={2} className='capitalize'>Legal</Heading>
                    <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                </Col>
            </Row>
            {open && (
                <>
                    <Heading block number={4}>{`Submission Agreement${apps.length !== 1 ? 's' : ''}`}</Heading>
                    {apps.map(applicant => (
                        <Row className='spacing-sm' key={`agreement-${applicant.name}`}>
                            <Col xs={12} sm={3}>
                                <Heading number={4} block>{applicant.name}</Heading>
                            </Col>
                            <Col xs={12} sm={9}>
                                <DownloadFile filename={applicant.agreement ? applicant.agreement.filename : 'N/A'} url={applicant.agreement ? applicant.agreement.url : '#'} />
                            </Col>
                        </Row>
                    ))}
                </>
            )}

            <hr className='Submission__divider__invisible' />

        </div>

    );
};

SubmissionLegal.propTypes = {
    applicants: PropTypes.array
};

export default SubmissionLegal;
