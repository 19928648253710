import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_SCORE } from '../../queries';
import { Heading, Caption } from '../modules/Typography';
import ScoreButton from './ScoreButton';
import { ReviewContext } from './SubmissionReviews';
import { SubmissionContext } from '../pages/Submission';

const ScoreCard = props => {
    const { status, currentStage } = useContext(SubmissionContext);
    let setOverallScore = null;
    if (!props.readOnly) {
        ({ setOverallScore } = useContext(ReviewContext));
    }
    const [scores, setScores] = useState(props.scores);
    const [sectionScore, setSectionScore] = useState(props.sectionScore);
    const [updateScore] = useMutation(UPDATE_SCORE);

    useEffect(() => {
        props.setScoreValid(!scores.find(score => score.value === null));
    }, [scores]);

    const handleScore = async ({ id, value }) => {
        const response = await updateScore({ variables: { id, value } });
        const review = response.data.updateScore.stages.find(stg => stg.reviews.length).reviews[0];
        setOverallScore(review.overallScore);
        setScores(review.sections[props.reviewIdx].scores);
        setSectionScore(review.sections[props.reviewIdx].overallScore);
    };

    return (
        <>
            <div className='ScoreCard'>
                <Heading className='ScoreCard__Title' block number={4}>Rating: 0 is poor, 5 is great</Heading>
                {scores.map(score => (
                    <ScoreButton
                        key={score.id}
                        id={score.id}
                        score={score.value}
                        text={score.name}
                        handleScore={handleScore}
                        canEdit={status !== 'COMPLETED' && !props.readOnly}
                    />
                ))}
                {currentStage <= 2 &&
                <div className='ScoreCard__Overall'>
                    <ScoreButton
                        score={sectionScore}
                        text='Overall Score'
                        canEdit={false}
                    />
                </div>}
            </div>
            {props.error && <Caption number={2} error className='TextArea__error'>Please fill out all scores.</Caption>}
        </>
    );
};

ScoreCard.propTypes = {
    scores: PropTypes.array,
    sectionScore: PropTypes.number,
    readOnly: PropTypes.bool,
    error: PropTypes.bool,
    setScoreValid: PropTypes.func,
    reviewIdx: PropTypes.number
};

export default ScoreCard;
