import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContextProvider';
import TopNav from './TopNav';
import SideNav from './SideNav';
import { Heading } from '../modules/Typography';

export const LayoutContext = React.createContext();

const Layout = props => {
    const { role } = useContext(UserContext);
    const navigate = useNavigate();
    const [sideNavOpen, setSideNavOpen] = useState(true);

    useEffect(() => {
        if (role === 'reviewer' && props.isAdmin) {
            navigate('/submissions');
        }
    }, [role]);

    return (
        <LayoutContext.Provider value={{ sideNavOpen, setSideNavOpen }}>
            <div className='layout'>
                <TopNav />
                <div className='SideNav__content__container flex align-flex-start'>
                    <SideNav />
                    <div className={`main-content ${sideNavOpen ? '' : 'closed'}`}>
                        <Heading className='page-title' number={1}>{props.title}</Heading>
                        {props.children}
                    </div>
                </div>
            </div>
        </LayoutContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.object,
    isAdmin: PropTypes.bool,
    title: PropTypes.string
};

Layout.defaultProps = {
    title: ''
};

export default Layout;
