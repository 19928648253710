import React, { useState, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { GET_REVIEWERS, UPDATE_STAGE, REJECT_SUBMISSION, ADD_TO_SHORTLIST } from '../../queries';
import { SubmissionContext } from '../pages/Submission';
import { Heading } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import Button from '../modules/Button';
import ConfirmationDialog from '../modules/ConfirmationDialog';
import AdminStage from './AdminStage';

export const ReviewContext = React.createContext();

const AdminSubmissionReviews = props => {
    const { currentStage, stages, submissionId, shortlisted, refetch } = useContext(SubmissionContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const navigate = useNavigate();
    const { isMobile } = useContext(WidthContext);
    const [dialog, setDialog] = useState('');
    const [loading, setLoading] = useState(false);

    const [updateStage] = useMutation(UPDATE_STAGE, { variables: { id: submissionId } });
    const [addToShortList] = useMutation(ADD_TO_SHORTLIST);
    const [rejectSubmission] = useMutation(REJECT_SUBMISSION, { variables: { id: submissionId } });

    const { data } = useQuery(GET_REVIEWERS, { variables: { offset: 0, limit: 1000, sort: 'lastName', sortBy: 'ASC', status: 'ACCEPTED' } });
    const reviewers = data ? data.reviewers.items : [];

    const handleStage = async () => {
        setLoading(true);
        try {
            await updateStage();
            refetch();
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }

        setLoading(false);
        setDialog('');
    };

    const handleReject = async () => {
        setLoading(true);
        try {
            await rejectSubmission();
            refetch();
            navigate(`/submissions/stage/${currentStage}`);
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
        setLoading(false);
        setDialog('');
    };

    const handleShortList = async () => {
        setLoading(true);
        try {
            await addToShortList({ variables: { id: submissionId, shortlisted: !shortlisted } });
            refetch();
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
        setLoading(false);
        setDialog('');
    };

    let title = 'Send to Next Stage?';
    let confirmText = 'Submit';
    let confirmFunc = handleStage;

    if (dialog === 'reject') {
        title = 'Reject Application?';
        confirmText = 'Reject';
        confirmFunc = handleReject;
    } else if (dialog === 'shortlist') {
        title = shortlisted ? 'Remove from Shortlist?' : 'Add to Shortlist?';
        confirmText = shortlisted ? 'Remove' : 'Add';
        confirmFunc = handleShortList;
    }

    return (
        <ReviewContext.Provider value={{ reviewers }}>
            <div className='Submission__Reviews'>
                <Row className='Submission__Reviews__Title'>
                    <Heading number={2}>Reviews</Heading>
                </Row>

                {Array.from(
                    new Array(currentStage),
                    (el, index) => (
                        <AdminStage key={`stage-${index}`} stage={stages[currentStage - index - 1]} />
                    ))}

                <Row>
                    <Col xs={10}>
                        <div className={`Submission__Buttons flex ${isMobile ? '' : 'flex-end'}`}>
                            <Button
                                className='Submission__Button'
                                size='large'
                                type='secondary-outline'
                                onClick={() => setDialog('shortlist')}
                            >{shortlisted ? 'Remove from Shortlist' : 'Add to Shortlist'}
                            </Button>
                            {currentStage <= 3 &&
                            <Button
                                className='Submission__Button'
                                size='large'
                                type='primary'
                                onClick={() => setDialog('send')}
                            >Send to Next Stage
                            </Button>}
                            <Button
                                className='Submission__Button'
                                size='large'
                                type='error'
                                onClick={() => setDialog('reject')}
                            >Reject
                            </Button>
                        </div>
                    </Col>
                </Row>
                <ConfirmationDialog
                    title={title}
                    confirmText={confirmText}
                    open={!!dialog}
                    onCancel={() => setDialog('')}
                    onConfirm={confirmFunc}
                    loading={loading}
                />
            </div>
        </ReviewContext.Provider>
    );
};

export default AdminSubmissionReviews;
