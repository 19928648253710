import { gql } from '@apollo/client';

export const GET_SUBMISSION = gql`
query getSubmission($id: String!) {
   submission(id: $id) {
      id
      acceptTerms
      eligible
      status
      shortlisted
      applicants {
        id
        name
        firstName
        lastName
        type
        email
        dob
        state
        zipcode
        phoneNumber
        eeo {
          optOut
          gender
          race
          ethnicity
          veteran
          disability
          employee
        }
        params {
          key
          value
        }
        uploads {
          id
          name
          filename
          url
          params {
            key
            value
          }
        }
        
      }
      uploads {
        id
        name
        filename
        url
        params {
          key
          value
        }
      }
      samples {
        id
        name
        url
        password
        params {
          key
          value
        }
        uploads {
          id
          name
          filename
          url
          params {
            key
            value
          }
        }
      }
      currentStage
      stages {
        stage 
        notes
        reviews {
          id
          reviewerNumber
          reviewer{
            firstName
            lastName
            name
            email
          }
          status
          overallScore
          recommended
          eligible
          reviewable
          sections {
            name
            overallScore
            scores {
              id
              name
              value
            }
            questions {
              id
              question
              answer
              inputType {
                type
                values
              }            
              
            }
          }
        }
      }
   }
 }
`;

export const GET_SUBMISSIONS = gql`
  query getSubmissions(
    $offset: Int!, 
    $limit: Int!, 
    $sort: String!, 
    $sortBy: SortBy!, 
    $stage: Int, 
    $status: String, 
    $shortlisted: Boolean, 
    $role: String, 
    $applicant: String,
    $reviewed: ReviewStatus,
    $seasonId: String
  ) {
    submissions(
      pagination: { offset: $offset, limit: $limit }
      filters: {
        stage: $stage, 
        status: $status, 
        shortlisted: $shortlisted, 
        role: $role, 
        applicant: $applicant, 
        reviewed: $reviewed,
        seasonId: $seasonId
      }
      orderBy: { sort: $sort, sortBy: $sortBy }
    ) {
      items {
        id
        ... on Submission {
            id
            status
            shortlisted
            currentStage
            applicants{
              id
              name
              type
            }
            stages (stage: $stage){
              stage
              reviews{
                id
                reviewerNumber
                reviewer{
                  name
                }
                status
                recommended
              }
              overallScore
            }
        }
      }
      totalQualifiedItems
      limit
      offset
      totalItems
    }
  }
`;

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($id: String!, $nextStage: Boolean, $status: SubmissionStatus, $shortlisted: Boolean) {
    updateSubmission(id: $id, nextStage: $nextStage, status: $status, shortlisted: $shortlisted) {
      shortlisted
      status
      ... on Submission {
        id
      }
    }
  }
`;

export const SEND_NEXT_STAGE = gql`
  mutation updateSubmission($id: String!, $nextStage: Boolean) {
    updateSubmission(id: $id, nextStage: $nextStage) {
      currentStage
      ... on Submission {
        id
      }
    }
  }
`;

export const SEND_PREV_STAGE = gql`
  mutation updateSubmission($id: String!, $prevStage: Boolean) {
    updateSubmission(id: $id, prevStage: $prevStage) {
      currentStage
      ... on Submission {
        id
      }
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation updateQuestion($id: String!, $value: String!)  {
    updateQuestion(id: $id, value: $value) {
      stages {
        reviews {
          sections {
            questions {
              id
              question
              answer
              inputType {
                type
                values
              }            
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_SCORE = gql`
  mutation updateScore($id: String!, $value: Int)  {
    updateScore(id: $id, value: $value) {
       stages {
        reviews {
          overallScore
          sections {
            overallScore
            scores {
              id
              name
               value
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_REVIEW = gql`
  mutation updateReview($id: String!, $recommended: Boolean, $eligible: Boolean, $reviewable: Boolean, $status: ReviewStatus) {
    updateReview(id: $id, review: {
      recommended: $recommended, reviewable: $reviewable, eligible: $eligible, status: $status
    }) {
      id
      stages {
        reviews {
          id
          status
        }
      }
    }
  }
`;

export const UPDATE_NOTE = gql`
  mutation updateNote($id: String!, $stage: Int!, $note: String!) {
    updateNote(applicationId: $id, stage: $stage, note: $note) {
      id
    }
  }
`;

export const UPDATE_STAGE = gql`
  mutation updateState($id: String!) {
    updateSubmission(id: $id, nextStage: true) {
      id
    }
  }
`;

export const REJECT_SUBMISSION = gql`
  mutation rejectSubmission($id: String!) {
    updateSubmission(id: $id, status: REJECTED) {
      id
    }
  }
`;

export const ADD_TO_SHORTLIST = gql`
  mutation addToShortList($id: String!, $shortlisted: Boolean) {
    updateSubmission(id: $id, shortlisted: $shortlisted) {
      id
      shortlisted
    }
  }
`;

export const DELETE_REVIEW = gql`
  mutation deleteReview($id: String!) {
    deleteReview(id: $id) {
     id
      stages {
        stage 
        reviews {
          id
          reviewerNumber
          status
          reviewer{
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`;

export const AUTO_ASSIGN = gql`
  mutation autoAssignReviewers($id: String!) {
    autoAssignReviewers(id: $id) {
      id
      stages {
        reviews {
          reviewer {
            id
            name
          }
        }
      }
    }
  }
`;

export const AUTOASSIGN_ALL_REVIEWERS = gql`
  mutation autoAssignAllReviewers($stage: Int!) {
    autoAssignAllReviewers(stage: $stage){
      message
    }
 }
`;
