import React, { useEffect, useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { GET_SUBMISSIONS, GET_REVIEWERS, AUTOASSIGN_ALL_REVIEWERS } from '../../queries';
import Button from '../modules/Button';
import Search from '../modules/Search';
import Filter from '../modules/Filter';
import Box from '../modules/Box';
import StageTable from '../stageDetail/StageTable';
import ApplicationTable from '../applicationAssignments/ApplicationTable';
import { UserContext } from '../../contexts/UserContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import ConfirmationDialog from '../modules/ConfirmationDialog';

export const SubmissionsContext = React.createContext();

const Submissions = props => {
    const location = useLocation();
    const navigate = useNavigate();
    const { role: userRole, season } = useContext(UserContext);
    const [role, setRole] = useState('all');
    const [status, setStatus] = useState('COMPLETED');
    const [reviewed, setReviewed] = useState(null);
    const [shortlisted, setShortlisted] = useState(null);
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [applicant, setApplicant] = useState('');
    const [totalItems, setTotalItems] = useState(0);
    const allowAutoAssign = false;
    const [sortBy, setSortBy] = useState('DESC');
    const [totalQualifiedItems, setTotalQualifiedItems] = useState(0);
    const [sort, setSort] = useState('created');
    const stage = location.pathname.split('/')[3] ? +location.pathname.split('/')[3] : null;
    const [autoAssignAllReviewers] = useMutation(AUTOASSIGN_ALL_REVIEWERS);
    const { errorSnackbar } = useContext(SnackbarContext);
    const [dialog, setDialog] = useState(false);
    const [loadingAutoassign, setLoading] = useState(false);

    const [getReviewers, { data: reviewerData }] = useLazyQuery(GET_REVIEWERS, { variables: { offset: 0, limit: 1000, sort: 'lastName', sortBy: 'ASC', status: 'ACCEPTED' } });
    const reviewers = reviewerData ? reviewerData?.reviewers?.items : [];

    useEffect(() => {
        if (userRole === 'REVIEWER') {
            navigate('/submissions');
        }
    }, [userRole]);

    useEffect(() => {
        if (reviewed) {
            setShortlisted(null);
            setStatus('COMPLETED');
        }
    }, [reviewed]);

    useEffect(() => {
        if (shortlisted) {
            setReviewed(null);
            setStatus('COMPLETED');
        }
    }, [shortlisted]);

    useEffect(() => {
        if (status === 'REJECTED') {
            setShortlisted(null);
            setReviewed(null);
        }
    }, [status]);

    useEffect(() => {
        setOffset(0);
    }, [applicant]);

    useEffect(() => {
        if (userRole === 'ADMIN' || 'SUPERADMIN') {
            getReviewers();
        }
    }, [userRole]);

    const formatRole = (roleType) => {
        let value = roleType.toLowerCase();
        if (roleType === 'Writer/Director') value = 'both';
        else if (roleType === 'all') value = '';
        return value;
    };

    const varRole = formatRole(role);
    const variables = {
        offset,
        limit,
        sort,
        sortBy,
        role: varRole,
        status,
        applicant,
        stage
    };
    if (reviewed !== null) {
        variables.reviewed = reviewed;
    }
    if (shortlisted !== null) {
        variables.shortlisted = shortlisted;
    }
    const { data, refetch, loading } = useQuery(GET_SUBMISSIONS, { variables: { offset, limit, sort, sortBy, role: varRole, status, applicant, shortlisted, stage, seasonId: season } });

    useEffect(() => {
        if (data) {
            setTotalQualifiedItems(data.submissions?.totalQualifiedItems);
            setTotalItems(data?.submissions?.totalItems);
        }
    }, [data]);

    useEffect(() => {
        refetch();
    }, [season]);

    const handleAutoAssignShortlist = () => {
        // TODO: ASSIGN SHORTLIST
    };

    const handleAutoAssignButtonTapped = async () => {
        setLoading(true);
        try {
            const enabledReviewers = await autoAssignAllReviewers({ variables: { stage } });
            if (enabledReviewers?.data) {
                refetch();
            }
        } catch (err) {
            errorSnackbar({ text: 'An error has occurred' });
        } finally {
            setLoading(false);
            setDialog(false);
        }
    };

    const handleDialog = (e) => {
        e.stopPropagation();
        setDialog(true);
    };

    const sortByDesc = () => {
        if (sort === 'created' && sortBy === 'DESC') {
            setSort('score');
        } else if (sort === 'score' && sortBy === 'DESC') {
            setSortBy('ASC');
        } else if (sort === 'score' && sortBy === 'ASC') {
            setSort('created');
            setSortBy('DESC');
        }
    };

    return (
        <SubmissionsContext.Provider
            value={{
                reviewers,
                shortlisted,
                data,
                status,
                loading,
                stage,
                totalQualifiedItems,
                offset,
                limit,
                sortBy,
                sort,
                refetch,
                setOffset,
                setLimit
            }}
        >
            <Box className='Submissions__box' borderType='none' type='white' padded={false}>
                <div className='Submissions__header flex flex-start'>
                    <Filter
                        role={role}
                        setRole={setRole}
                        setStatus={setStatus}
                        status={status}
                        reviewed={reviewed}
                        setReviewed={setReviewed}
                        setShortlisted={setShortlisted}
                        shortlisted={shortlisted}
                    />
                    {allowAutoAssign &&
                    <Button
                        className='Submissions__header__AutoAssign'
                        type='secondary-outline'
                        color='default'
                        size='large'
                        text='Auto Assign'
                        onClick={handleAutoAssignShortlist}
                    />}
                </div>
                <Search offset={offset} limit={limit} setApplicant={setApplicant} totalItems={totalItems} />
                <Button
                    className='ApplicationAction__Button__Autoassign'
                    type='primary'
                    color='default'
                    size='large'
                    text='Auto-assign all Reviewers'
                    loading={false}
                    onClick={(e) => handleDialog(e)}
                />
                <div className='Submissions__table__wrapper'>
                    <div className='Submissions__table'>
                        {stage ? <StageTable sortBy={sortByDesc} sortOrder={sortBy} sort={sort} /> : <ApplicationTable />}
                    </div>
                </div>
                <ConfirmationDialog
                    title='Are you sure you want to automatically assign reviewers? This could take a while.'
                    confirmText='Confirm'
                    open={!!dialog}
                    onCancel={() => [setDialog(false), setLoading(false)]}
                    onConfirm={(e) => handleAutoAssignButtonTapped()}
                    loading={loadingAutoassign}
                />
            </Box>
        </SubmissionsContext.Provider>
    );
};

Submissions.propTypes = {};

export default Submissions;
