import React, { useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import queryString from 'query-string';
import { Body } from '../modules/Typography';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import Icon from '../modules/Icon';
import { UserContext } from '../../contexts/UserContextProvider';
import fetch from '../../utils/fetch';
import logo from '../../images/launchpad-logo-blue.svg';

const Login = props => {
    const { setToken } = useContext(UserContext);
    const [showPassword, setShow] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const testEmail = (e) => {
        const { value } = e.target;
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(value)) {
            setErrors(errors.filter(error => error !== 'email'));
        } else {
            setErrors([...errors, 'email']);
        }
    };

    const handleEmail = (e) => {
        const { value } = e.target;
        setEmail(value);
    };
    const handlePassword = (e) => {
        const { value } = e.target;
        setPassword(value);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch('/auth/sentry/login', {
                method: 'POST',
                body: {
                    username: email,
                    password
                }
            });
            if (response.error) {
                const errorDescription = response.error_description;
                if (errorDescription === 'account not found') {
                    setErrorMessage('Account Not Found');
                    setErrors(['email']);
                } else if (errorDescription.includes('Incorrect username or password')) {
                    setErrorMessage('Incorrect password');
                    setErrors(['password']);
                }
            } else {
                setToken({ accessToken: response.access_token, refreshToken: response.refresh_token });
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleMyId = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const config = await fetch('/api/config');
        const { baseUrl, clientId, redirectUri } = config.sentry;
        const redirect = `${location.origin}${redirectUri}`;
        const params = queryString.stringify({
            client_id: clientId,
            response_type: 'code',
            redirect_uri: redirect
        });
        const sentryUrl = `${baseUrl}/oauth/authorize?${params}`;
        location.replace(sentryUrl);
    };

    return (
        <div className='Login'>
            <Helmet><title>Disney Launchpad: Shorts Incubator | Login</title></Helmet>
            <Modal show onHide={() => {}} className='Login__Modal'>
                <img src={logo} />
                <div className='form-group spacing-sm'>
                    <Button block type='primary' className='flex center' onClick={handleMyId}>Sign In with MyID</Button>
                    <Body number={1} block className='Login__divider'>or</Body>

                    <TextField
                        placeholder='Email Address*'
                        label='Email Address'
                        value={email}
                        onKeyPress={handleKeyPress}
                        onChange={handleEmail}
                        onBlur={testEmail}
                        error={errors.includes('email')}
                        errorMessage={errorMessage || 'Please enter a valid email'}
                    />
                </div>
                <div className='flex align-center Login__Modal__password form-group'>
                    <TextField
                        className='right-10'
                        padded={false}
                        placeholder='Password*'
                        label='Password'
                        value={password}
                        type={showPassword ? 'text' : 'password'}
                        onKeyPress={handleKeyPress}
                        onChange={handlePassword}
                        error={errors.includes('password')}
                        errorMessage={errorMessage || 'Please enter a valid password'}
                    />
                    <Icon className='button Login__Modal__eye' name={`visibility-${showPassword ? 'off' : 'on'}`} onClick={() => setShow(!showPassword)} />
                </div>
                <Button block type='tertiary' link='/forgot-password'>Forgot Password?</Button>
                <Modal.Footer>
                    <div className='btn-container'>
                        <Button block type='primary' disabled={errors.length || !email || !password} onClick={handleSubmit}>Sign In</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Login;
