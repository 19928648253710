import React, { useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { GET_REVIEWERS, RESEND_INVITE, UPDATE_AUTOASSIGN } from '../../queries';
import Button from '../modules/Button';
import Table from '../modules/Table';
import Switch from '../modules/Switch';

const ReviewTable = props => {
    const [limit, setLimit] = useState(25);
    const [offset, setOffset] = useState(0);
    const [sortBy, setSortBy] = useState('DESC');
    const [sort, setSort] = useState('updated');
    const [assignee, setAssignee] = useState({});
    const [reviewers, setReviewers] = useState([]);
    const pageSizeOptions = ['25', '50', '75', '100'];
    const [getReviewers, { data }] = useLazyQuery(GET_REVIEWERS);
    const [resendInvite] = useMutation(RESEND_INVITE);
    const [updateAutoAssign] = useMutation(UPDATE_AUTOASSIGN);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        getReviewers({ variables: { offset, limit, sort, sortBy } });
    }, [offset, limit, sort, sortBy, props.inviteSent]);

    useEffect(() => {
        if (data) {
            setTotalItems(data.reviewers?.totalItems);
            setReviewers(data.reviewers?.items);
        };
    }, [data]);

    useEffect(() => {
        const reviewer = { ...reviewers.find(rev => rev.id === assignee.id) };
        if (reviewer.id) {
            reviewer.autoAssign = assignee.canAutoAssign;
            setReviewers(reviewers => reviewers.map(rev => rev.id === reviewer.id ? reviewer : rev));
        }
    }, [assignee]);

    const tableData = reviewers?.map(reviewer => (
        {
            id: reviewer.id,
            name: reviewer.name,
            email: reviewer.email,
            role: reviewer.role.toLowerCase(),
            status: reviewer.status.toLowerCase(),
            autoAssign: reviewer.autoAssign,
            assignedApplications: reviewer.assignedApplications,
            completedApplications: reviewer.completedApplications,
            reInvite: reviewer.status.toUpperCase() !== 'ACCEPTED'
        }
    ));

    const filteredTable = tableData.filter(item => item.status.toLowerCase() !== 'deactivated');

    const handleResendInvite = (id) => {
        try {
            resendInvite({ variables: { id } });
        } catch (err) {
            console.log(err);
        }
    };

    const handleAssign = async (id, canAutoAssign) => {
        await updateAutoAssign({ variables: { id, canAutoAssign } });
        setAssignee({ id, canAutoAssign });
    };

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            width: 235
        },
        {
            Header: 'Email',
            accessor: 'email',
            className: 'Typography Typography--body1',
            width: 250
        },
        {
            Header: 'Role',
            accessor: 'role'
        },
        {
            Header: 'Applications Assigned',
            accessor: 'assignedApplications',
            width: 200
        },
        {
            Header: 'Completed',
            accessor: 'completedApplications',
            width: 110
        },
        {
            Header: 'Auto-Assignable',
            accessor: 'autoAssign',
            Cell: cell => cell.row.original.role === 'reviewer' && !cell.row.original.reInvite ? (
                <Switch checked={cell.value} onChange={(e) => handleAssign(cell.row.original.id, e.target.checked)} />
            ) : (
                'N/A'
            )
        },
        {
            Header: 'Invitation Status',
            accessor: 'status'
        },
        {
            Header: 'Action',
            accessor: 'reInvite',
            width: 100,
            Cell: cell => cell.value ? <Button type='secondary-outline' color='default' size='small' text='Reinvite' onClick={() => { handleResendInvite(cell.row.original.id); }} /> : ''
        }
    ];

    return (
        <Table
            loading={!data}
            columns={columns}
            data={filteredTable}
            pageSizeOptions={pageSizeOptions}
            setLimit={setLimit}
            setOffset={setOffset}
            setSortBy={setSortBy}
            setSort={setSort}
            limit={limit}
            sortBy={sortBy}
            offset={offset}
            sort={sort}
            totalItems={totalItems}
            pageCount={Math.round(totalItems / limit)}
        />
    );
};

ReviewTable.propTypes = {
    inviteSent: PropTypes.bool

};

export default ReviewTable;
