import React from 'react';
import { Helmet } from 'react-helmet-async';
import { saveAs } from 'file-saver';
import Button from '../modules/Button';
import { Container } from '../modules/Grid';
import fetch from '../../utils/fetch';

const Exports = props => {
    const handleExport = async () => {
        const response = await fetch('/api/email-exports');
        console.log('response', response);
        saveAs(response, 'launchpad-emails.csv');
    };

    return (

        <div className='Exports'>
            <Helmet><title>Launchpad Back Office | Exports</title></Helmet>
            <Container>
                <Button icon='download-b' color='white' onClick={handleExport}>Export Email Signups</Button>
            </Container>
        </div>
    );
};

export default Exports;
