import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_SUBMISSION, SEND_NEXT_STAGE, SEND_PREV_STAGE, AUTO_ASSIGN } from '../../queries';
import Button from '../modules/Button';
import { SubmissionsContext } from '../pages/Submissions';
import { UserContext } from '../../contexts/UserContextProvider';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import ConfirmationDialog from '../modules/ConfirmationDialog';

const ApplicationAction = props => {
    const { refetch, shortlisted } = useContext(SubmissionsContext);
    const { role } = useContext(UserContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const [updateSubmission] = useMutation(UPDATE_SUBMISSION);
    const [sendNextStage] = useMutation(SEND_NEXT_STAGE);
    const [sendPrevStage] = useMutation(SEND_PREV_STAGE);
    const [autoAssign] = useMutation(AUTO_ASSIGN, { variables: { id: props.rowData.id } });
    const [submissionStatus, setSubmissionStatus] = useState(props.status || 'COMPLETED');
    const [shortlist, setShortlist] = useState(props.rowData.shortlisted);
    const [assigning, setAssigning] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [stageDirection, setStageDirection] = useState('');

    const handleStatusButtonTapped = async (e) => {
        e.stopPropagation();
        const updateStatus = submissionStatus !== 'REJECTED' ? 'REJECTED' : 'COMPLETED';
        try {
            await updateSubmission({ variables: { id: props.rowData.id, status: updateStatus } });
            setSubmissionStatus(updateStatus);
            refetch();
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
    };

    const handleStageButtonTapped = async (e, direction) => {
        e.stopPropagation();
        setLoading(true);
        try {
            if (direction === 'Next') {
                await sendNextStage({ variables: { id: props.rowData.id, nextStage: true } });
            } else if (direction === 'Previous') {
                await sendPrevStage({ variables: { id: props.rowData.id, prevStage: true } });
            }
        } catch (err) {
            console.log(err);
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
        setLoading(false);
        setDialog('');
        refetch();
    };

    const handleShortlisted = async (e) => {
        e.stopPropagation();
        try {
            await updateSubmission({ variables: { id: props.rowData.id, shortlisted: !shortlist } });
            if (shortlisted) {
                refetch();
            }
            setShortlist(shortlist => !shortlist);
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
    };

    const handleAssign = async (e) => {
        e.stopPropagation();
        try {
            await autoAssign();
            setAssigning(true);
            refetch();
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
        setAssigning(false);
        e.stopPropagation();
    };

    const handleDialog = (e, direction) => {
        e.stopPropagation();
        setDialog(true);
        setStageDirection(direction);
    };
    return (
        <div className='ApplicationAction flex flex-start'>
            {(role === 'ADMIN' || role === 'SUPERADMIN') && (
                <>
                    <Button
                        className='ApplicationAction__Button shortlist'
                        type='secondary-outline'
                        color='default'
                        size='small'
                        text={shortlist ? 'Remove from Shortlist' : 'Add to Shortlist'}
                        onClick={handleShortlisted}
                    />
                    {props.stage <= 3 &&
                        <Button
                            className='ApplicationAction__Button'
                            type='secondary-outline'
                            color='default'
                            size='small'
                            text='Send to Next Stage'
                            onClick={(e) => handleDialog(e, 'Next')}
                        />}

                    {props.stage >= 2 &&
                    <Button
                        className='ApplicationAction__Button'
                        type='secondary-outline'
                        color='default'
                        size='small'
                        text='Send to Previous Stage'
                        onClick={(e) => handleDialog(e, 'Previous')}
                    />}
                    <Button
                        className='ApplicationAction__Button Reconsider__Button'
                        type='secondary-outline'
                        color='default'
                        size='small'
                        text={(submissionStatus !== 'REJECTED') ? 'Reject' : 'Reconsider'}
                        onClick={handleStatusButtonTapped}
                    />
                    <Button
                        className='ApplicationAction__Button'
                        type='secondary-outline'
                        color='default'
                        size='small'
                        text='Auto-assign Reviewers'
                        loading={assigning}
                        onClick={handleAssign}
                    />
                    <ConfirmationDialog
                        title={`Send to ${stageDirection} Stage?`}
                        confirmText='Submit'
                        open={!!dialog}
                        onCancel={() => setDialog(false)}
                        onConfirm={(e) => handleStageButtonTapped(e, stageDirection)}
                        loading={loading}
                    />
                </>
            )}
        </div>
    );
};
export default ApplicationAction;

ApplicationAction.propTypes = {
    /* Row original information */
    rowData: PropTypes.object.isRequired,

    /* Current stage visible */
    stage: PropTypes.number,

    /* Submission status */
    status: PropTypes.string
};

ApplicationAction.defaultProps = {
    stage: 1
};
