import React from 'react';
import PropTypes from 'prop-types';
import DialogWrapper from './DialogWrapper';

const ConfirmationDialog = props => (
    <DialogWrapper
        title={props.title}
        open={props.open}
        onCancel={props.onCancel}
        onConfirm={props.onConfirm}
        confirmText={props.confirmText}
        padded
        loading={props.loading}
    >
        {props.text}
    </DialogWrapper>
);

export default ConfirmationDialog;

ConfirmationDialog.propTypes = {
    // whether or not the dialog isopen
    open: PropTypes.bool.isRequired,

    // title of the dialog
    title: PropTypes.string,

    // body text of the dialog
    text: PropTypes.string,
    confirmText: PropTypes.string,

    // function to cancel or close the dialog
    onCancel: PropTypes.func,
    loading: PropTypes.bool,

    // function to confirm the dialog
    onConfirm: PropTypes.func
};
