import React, { useContext } from 'react';
import { DashboardContext } from '../pages/Dashboard';
import DashboardTable from './DashboardTable';
import { Heading } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import submissionConfig from '../submissionDetail/submissionConfig';

const { hearOptions, races, genders, disabilities, veterans, ethnicities, employees } = submissionConfig;
const steps = ['', 'Personal Information', 'Uploads', 'Legal', 'Equal Employment', 'Review'];

const DashboardTables = props => {
    const {
        applicationStepsCompleted,
        reviewsCompletedByReviewer,
        applicantsByState,
        applicantsByRace,
        applicantsByHeard,
        applicantsByGender,
        applicantsByEthnicity,
        applicantsByVeteranStatus,
        applicantsByDisability,
        applicantsEmployedByDisney
    } = useContext(DashboardContext);

    return (
        <div className='Dashboard__Tables'>
            <DashboardTable
                title='Application Steps Reached'
                columns={[
                    {
                        Header: 'Step',
                        accessor: 'step'
                    },
                    {
                        Header: 'Number Completed',
                        accessor: 'completed',
                        className: 'Table__padding__large'
                    }
                ]}
                data={applicationStepsCompleted?.map(stage => ({
                    step: steps[stage.step],
                    completed: stage.completed
                }))}
            />
            {reviewsCompletedByReviewer?.map((stage, idx) => (
                <DashboardTable
                    key={`Table-${idx}`}
                    title={`Stage ${stage.stage} Reviews Completed by Reviewers`}
                    columns={[
                        {
                            Header: 'Reviewer',
                            accessor: 'email'
                        },
                        {
                            Header: 'Completed Reviews',
                            accessor: 'completed',
                            className: 'Table__padding__large'
                        }
                    ]}
                    data={stage.reviewerDetails?.map(reviewer => (
                        {
                            email: reviewer.email,
                            completed: reviewer.completed
                        }
                    ))}
                />
            ))}
            <Row>
                <Col md={6}>
                    <DashboardTable
                        title='Number of Applications by State'
                        columns={[
                            {
                                Header: 'State',
                                accessor: 'state'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByState?.map(app => {
                            return ({
                                state: app.state || 'N/A',
                                totalApplicants: app.totalApplicants || 0
                            });
                        })}
                    />
                </Col>
                <Col md={6}>
                    <DashboardTable
                        title='Number of Applications by How Heard'
                        columns={[
                            {
                                Header: 'How Heard',
                                accessor: 'heard'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByHeard?.map(app => {
                            return ({
                                heard: app.heard ? hearOptions.find(opt => opt.value === app.heard).text : 'N/A',
                                totalApplicants: app.totalApplicants || 0
                            });
                        })}
                    />
                </Col>
            </Row>
            <Heading number={2} block className='Dashboard__Table__title'>Demographics</Heading>
            <Row>
                <Col xl={6} lg={12}>

                    <DashboardTable
                        title='Race'
                        columns={[
                            {
                                Header: 'Identifier',
                                accessor: 'race'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByRace
                            ?.filter(app => app.race)
                            ?.map(app => {
                                return ({
                                    race: races.find(opt => opt.value === app.race).text,
                                    totalApplicants: app.totalApplicants || 0
                                });
                            })}
                    />
                </Col>
                <Col xl={6} lg={12}>

                    <DashboardTable
                        title='Gender'
                        columns={[
                            {
                                Header: 'Identifier',
                                accessor: 'gender'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByGender
                            ?.filter(app => app.gender)
                            ?.map(app => {
                                return ({
                                    gender: genders.find(opt => opt.value === app.gender).text,
                                    totalApplicants: app.totalApplicants || 0
                                });
                            })}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={6} lg={12}>

                    <DashboardTable
                        title='Ethnicity'
                        columns={[
                            {
                                Header: 'Identifier',
                                accessor: 'ethnicity'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByEthnicity
                            ?.filter(app => app.ethnicity)
                            ?.map(app => {
                                return ({
                                    ethnicity: ethnicities.find(opt => opt.value === app.ethnicity).text,
                                    totalApplicants: app.totalApplicants || 0
                                });
                            })}
                    />
                </Col>
                <Col xl={6} lg={12}>

                    <DashboardTable
                        title='Veteran Status'
                        columns={[
                            {
                                Header: 'Identifier',
                                accessor: 'veteranStatus'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByVeteranStatus
                            ?.filter(app => app.veteranStatus)
                            ?.map(app => {
                                return ({
                                    veteranStatus: veterans.find(opt => opt.value === app.veteranStatus).text,
                                    totalApplicants: app.totalApplicants || 0
                                });
                            })}
                    />
                </Col>
            </Row>
            <Row>
                <Col xl={6} lg={12}>

                    <DashboardTable
                        title='Disability'
                        columns={[
                            {
                                Header: 'Identifier',
                                accessor: 'disability'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsByDisability
                            ?.filter(app => app.disability)
                            ?.map(app => {
                                return ({
                                    disability: disabilities.find(opt => opt.value === app.disability).text,
                                    totalApplicants: app.totalApplicants || 0
                                });
                            })}
                    />
                </Col>
                <Col xl={6} lg={12}>

                    <DashboardTable
                        title='Employee Status'
                        columns={[
                            {
                                Header: 'Identifier',
                                accessor: 'employedByDisney'
                            },
                            {
                                Header: 'No. of Applicants',
                                accessor: 'totalApplicants',
                                className: 'Table__padding'
                            }
                        ]}
                        data={applicantsEmployedByDisney
                            ?.filter(app => app.employedByDisney)
                            ?.map(app => {
                                return ({
                                    employedByDisney: employees.find(opt => opt.value === app.employedByDisney).text,
                                    totalApplicants: app.totalApplicants || 0
                                });
                            })}
                    />
                </Col>

            </Row>
        </div>
    );
};

export default DashboardTables;
