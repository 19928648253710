import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Body } from '../modules/Typography';
import Table from '../modules/Table';
import Tooltip from '../modules/Tooltip';
import StageReviewer from './StageReviewer';
import ApplicationAction from './ApplicationAction';
import CircleIcon from './CircleIcon';
import Icon from '../modules/Icon';
import { SubmissionsContext } from '../pages/Submissions';

const StageTable = props => {
    const navigate = useNavigate();
    const { loading, data, offset, limit, setOffset, setLimit, stage, status, sortBy, sort, totalQualifiedItems } = useContext(SubmissionsContext);
    const pageSizeOptions = ['25', '50', '75', '100'];
    const currStage = stage;
    const submissions = data ? data.submissions : {};
    const totalItems = submissions.totalItems || 0;

    if (!loading) {
        var tableData = submissions?.items?.filter(sub => {
            return (status === 'REJECTED' && sub.status === 'REJECTED') || (status !== 'REJECTED' && sub.status !== 'REJECTED');
        }).map((submission, index) => {
            const { id, status, shortlisted, applicants, stages } = submission;
            const stage = stages[0];
            const { overallScore, reviews } = stage;
            const reviewOne = reviews.find(reviewer => reviewer.reviewerNumber === 1);
            const reviewTwo = reviews.find(reviewer => reviewer.reviewerNumber === 2);
            const reviewThree = reviews.find(reviewer => reviewer.reviewerNumber === 3);
            const rank = sort === 'score' ? (overallScore ? (sortBy === 'DESC' ? (index + 1 + offset) : (totalQualifiedItems - (index) - offset)) : 'NA') : '-';
            return (
                {
                    id,
                    applicants: applicants.map(applicant => applicant.name),
                    role: applicants[0].type === 'both' ? 'Writer / Director' : applicants[0].type,
                    rank,
                    reviewOne,
                    reviewTwo,
                    reviewThree,
                    average: overallScore || 'N/A',
                    recommended: [reviewOne ? reviewOne.recommended : 'N/A', reviewTwo ? reviewTwo.recommended : 'N/A', reviewThree ? reviewThree.recommended : 'N/A'],
                    status,
                    shortlisted
                }
            );
        });
    }
    const handleRowClick = ({ original }) => {
        navigate(`/submissions/${original.id}`);
    };

    const columns = [
        {
            Header: 'Applicant(s)',
            accessor: 'applicants',
            width: 200,
            Cell: cell => (
                <div className='Tooltip__revealer'>
                    <Body className='capitalize' number={1} color='primary'>
                        {cell.value[0]}
                        {cell.value.length > 1 && '...'}
                    </Body>
                    {cell.value.length > 1 && (<Tooltip>{cell.value.slice(1).join(', ')}</Tooltip>)}
                </div>
            )
        },
        {
            Header: 'Role',
            accessor: 'role',
            width: 80
        },
        {
            Header: 'Reviewer 1',
            accessor: 'reviewer1',
            width: 155,
            Cell: cell => (
                <StageReviewer
                    reviewer={cell.row.original.reviewOne ? cell.row.original.reviewOne.reviewer.name : ''}
                    status={cell.row.original.reviewOne ? cell.row.original.reviewOne.status : ''}
                    reviewId={cell.row.original.reviewOne ? cell.row.original.reviewOne.id : ''}
                    submissionId={cell.row.original.id}
                    reviewerNumber={1}
                    stage={currStage}
                />)
        },
        {
            Header: 'Reviewer 2',
            accessor: 'reviewer2',
            width: 155,
            Cell: cell => (
                <StageReviewer
                    reviewer={cell.row.original.reviewTwo ? cell.row.original.reviewTwo.reviewer.name : ''}
                    status={cell.row.original.reviewTwo ? cell.row.original.reviewTwo.status : ''}
                    reviewId={cell.row.original.reviewTwo ? cell.row.original.reviewTwo.id : ''}
                    submissionId={cell.row.original.id}
                    reviewerNumber={2}
                    stage={currStage}
                />)
        },
        {
            Header: 'Reviewer 3',
            accessor: 'reviewer3',
            width: 155,
            Cell: cell => (
                <StageReviewer
                    reviewer={cell.row.original.reviewThree ? cell.row.original.reviewThree.reviewer.name : ''}
                    status={cell.row.original.reviewThree ? cell.row.original.reviewThree.status : ''}
                    reviewId={cell.row.original.reviewThree ? cell.row.original.reviewThree.id : ''}
                    submissionId={cell.row.original.id}
                    reviewerNumber={3}
                    stage={currStage}
                />)
        },
        {
            Header: average => (
                <div>
                    Average
                    <Icon
                        className={sort === 'created' ? 'Submissions__table__arrow__disabled' : 'Submissions__table__arrow'}
                        name={sortBy === 'DESC' ? 'caret-down' : 'caret-up'}
                        onClick={() => props.sortBy()}
                    />
                </div>),
            accessor: 'average',
            width: 100,
            Cell: cell => (
                <div className='CircleIcon__position__center'>
                    <CircleIcon
                        className='flex center'
                        text={cell.row.original.average}
                    />
                </div>)
        },
        {
            Header: 'Recommendation',
            accessor: 'recommendation',
            width: 150,
            Cell: cell => (
                <div className={`${currStage > 2 ? 'CircleIcon__position flex' : 'flex'}`}>
                    <CircleIcon
                        text={cell.row.original.recommended[0]}
                    />
                    {currStage < 3 && (
                        <>
                            <CircleIcon
                                text={cell.row.original.recommended[1]}
                            />
                            <CircleIcon
                                text={cell.row.original.recommended[2]}
                            />
                        </>
                    )}
                </div>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            width: stage === 4 ? 600 : 740,
            Cell: cell => (
                <ApplicationAction
                    rowData={cell.row.original}
                    stage={currStage}
                    status={cell.row.original.status}

                />
            )
        }
    ];

    if (status === 'COMPLETED') {
        columns.unshift({
            Header: 'Rank',
            accessor: 'rank',
            width: 80
        });
    }

    if (currStage > 2) {
        columns.splice(3, 2);
    }

    return (
        <>
            <Table
                columns={columns}
                isApplicationTable
                data={tableData}
                loading={loading}
                handleRowClick={handleRowClick}
                selectable
                pagination
                pageSizeOptions={pageSizeOptions}
                setLimit={setLimit}
                setOffset={setOffset}
                limit={limit}
                offset={offset}
                totalItems={totalItems}
                pageCount={Math.round(totalItems / limit)}
            />
        </>
    );
};

StageTable.propTypes = {
    sortBy: PropTypes.func
};

export default StageTable;
