import React, { useContext, useState } from 'react';
// import { WidthContext } from '../../contexts/WidthContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { Link, useLocation } from 'react-router-dom';
import Icon from '../modules/Icon';
import { Heading } from '../modules/Typography';
import { LayoutContext } from './Layout';

const SideNav = props => {
    // const { isMobile } = useContext(WidthContext);
    const [hoverSubmenu, setHoverSubmenu] = useState(false);
    const { role } = useContext(UserContext);
    const { sideNavOpen } = useContext(LayoutContext);
    const [stagesOpen, setStagesOpen] = useState(true);
    const location = useLocation();
    const { pathname } = location;
    const path = pathname.split('/')[1];

    const openStages = (e) => {
        e.preventDefault();
        setStagesOpen(!stagesOpen);
    };
    return (
        <>
            {(role === 'ADMIN' || 'SUPERADMIN') &&
            <div className={`SideNav flex column flex-start ${sideNavOpen ? 'open' : ''}`}>
                <Link className='SideNav__menu' to='/dashboard'>
                    <div className={`SideNav__Link flex flex-start align-baseline ${path === 'dashboard' ? 'active' : ''}`}>
                        <Icon className='SideNav__Link__icon' name='home' />
                        {sideNavOpen && <Heading number={6}>Dashboard</Heading>}
                    </div>
                </Link>
                <Link className='SideNav__menu' to='/manage-reviewers'>
                    <div className={`SideNav__Link flex flex-start align-baseline ${path === 'manage-reviewers' ? 'active' : ''}`}>
                        <Icon className='SideNav__Link__icon' name='clipboard' />
                        {sideNavOpen && <Heading number={6}>Manage Reviewers</Heading>}
                    </div>
                </Link>
                {/* <Link className='SideNav__menu' to='/submissions'> */}
                <Link className='SideNav__menu' to='/submissions/stage/1'>
                    <div onMouseEnter={() => setHoverSubmenu(true)} onMouseLeave={() => setHoverSubmenu(false)} className={`SideNav__Link flex flex-start align-baseline ${pathname === '/submissions' || (path === 'submissions' && !stagesOpen) ? 'active' : ''}`}>
                        <Icon className='SideNav__Link__icon' name='edit' />
                        {sideNavOpen && <Heading number={6}>Submissions<Icon className='SideNav__Link__caret pointer' name={stagesOpen ? 'caret-down' : 'caret-right'} onClick={openStages} /></Heading>}
                    </div>
                </Link>
                <div className={sideNavOpen ? (stagesOpen ? 'sidebar__category-wrap' : 'SideNav__hover-menu__none') : (hoverSubmenu ? 'SideNav__submenu-wrap' : 'SideNav__hover-menu__none')} onMouseEnter={() => setHoverSubmenu(true)} onMouseLeave={() => setHoverSubmenu(false)}>
                    <Link to='/submissions/stage/1'>
                        <div className={`${hoverSubmenu ? 'SideNav__Link__hover' : 'SideNav__Link__stage'} ${pathname === '/submissions/stage/1' ? 'active' : ''}`}>
                            <Heading className='sidebar__link-title' number={6}>Stage 1</Heading>
                        </div>
                    </Link>
                    <Link to='/submissions/stage/2'>
                        <div className={`${hoverSubmenu ? 'SideNav__Link__hover' : 'SideNav__Link__stage'} ${pathname === '/submissions/stage/2' ? 'active' : ''}`}>
                            <Heading className='sidebar__link-title' number={6}>Stage 2</Heading>
                        </div>
                    </Link>
                    <Link to='/submissions/stage/3'>
                        <div className={`${hoverSubmenu ? 'SideNav__Link__hover' : 'SideNav__Link__stage'} ${pathname === '/submissions/stage/3' ? 'active' : ''}`}>
                            <Heading className='sidebar__link-title' number={6}>Stage 3</Heading>
                        </div>
                    </Link>
                    <Link to='/submissions/stage/4'>
                        <div className={`${hoverSubmenu ? 'SideNav__Link__hover' : 'SideNav__Link__stage'} ${pathname === '/submissions/stage/4' ? 'active' : ''}`}>
                            <Heading className='sidebar__link-title' number={6}>Stage 4</Heading>
                        </div>
                    </Link>
                </div>
                <Link className='SideNav__menu' to='/exports'>
                    <div className='SideNav__Link flex flex-start align-baseline'>
                        <Icon className='SideNav__Link__icon' name='download-b' />
                        {sideNavOpen && <Heading number={6}>Exports</Heading>}
                    </div>
                </Link>
                {(role === 'SUPERADMIN') &&
                <Link className='SideNav__menu' to='/manage-seasons'>
                    <div className='SideNav__Link flex flex-start align-baseline'>
                        <Icon className='SideNav__Link__icon' name='calendar' />
                        {sideNavOpen && <Heading number={6}>Manage Seasons</Heading>}
                    </div>
                </Link>}
                {/* <Link className='padded' to='/graphiql'>
                        graphiql
                    </Link> */}
            </div>}
            {(role === 'REVIEWER') &&
                <div className={`SideNav flex column flex-start ${sideNavOpen ? 'open' : ''}`}>
                    <Link className='SideNav__menu' to='/submissions'>
                        <div onMouseEnter={() => setHoverSubmenu(true)} onMouseLeave={() => setHoverSubmenu(false)} className={`SideNav__Link flex flex-start align-baseline ${path === 'submissions' ? 'active' : ''}`}>
                            <Icon className='SideNav__Link__icon' name='edit' />
                            {sideNavOpen && <Heading number={6}>Submissions</Heading>}
                        </div>
                    </Link>
                </div>}
        </>
    );
};

export default SideNav;
