import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { SubmissionContext } from '../pages/Submission';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import Button from '../modules/Button';
import IconButton from '../modules/IconButton';
import DownloadFile from '../modules/DownloadFile';

const SubmissionApplicant = props => {
    const {
        // races,
        // genders,
        // veterans,
        // disabilities,
        // employees,
        hearOptions,
        states,
        allExpanded,
        setExpanded
    } = useContext(SubmissionContext);
    const [open, setOpen] = useState(false);
    const { isMobile } = useContext(WidthContext);

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);
    const {
        name,
        type,
        state,
        zipcode,
        dob,
        email,
        phoneNumber,
        firstName,
        lastName,
        params,
        uploads
        // eeo
    } = props.applicant;
    const passion = params?.find(param => param.key === 'passion').value;
    const collaborate = params?.find(param => param.key === 'collaborate').value;
    const bio = params?.find(param => param.key === 'bio').value;
    const howHeard = params?.find(param => param.key === 'howHeard').value;
    const resume = uploads.find(upload => upload.name === 'resume') || {};
    const reference = uploads.find(upload => upload.name === 'reference');
    // const { optOut } = eeo;

    const stateName = states?.find(st => st.value === state)?.text;
    const hearOption = hearOptions?.find(option => option.value === howHeard)?.text;
    // let gender = '';
    // let race = '';
    // let ethnicity = '';
    // let veteran = '';
    // let disability = '';
    // let employee = '';

    // if (eeo.gender) {
    //     gender = genders.find(option => option.value === eeo.gender).text;
    // }
    // if (eeo.race) {
    //     race = races.find(option => option.value === eeo.race).text;
    // }
    // if (eeo.ethnicity) {
    //     ethnicity = eeo.ethnicity === 'hispanic' ? 'Hispanic or Latino' : 'Not Hispanic or Latino';
    // }
    // if (eeo.veteran) {
    //     veteran = veterans.find(option => option.value === eeo.veteran).text;
    // }
    // if (eeo.disability) {
    //     disability = disabilities.find(option => option.value === eeo.disability).text;
    // }
    // if (eeo.employee) {
    //     employee = employees.find(option => option.value === eeo.employee).text;
    // }

    // const eeoText = optOut ? 'Opted Out' : `${race},${' '}${ethnicity},${' '}${gender}, ${' '}${veteran}, ${' '}${disability}, ${' '}${employee}`;
    return (
        <div className='Submission__Applicant'>
            <Row>
                <Col xs={12} className='flex'>
                    <Heading number={2} className='capitalize'>{type}: {name}</Heading>
                    {props.idx === 0 ? <Button size={isMobile ? 'small' : 'regular'} type='tertiary' onClick={() => setExpanded(!allExpanded)}>{allExpanded ? 'Collapse All' : 'Expand All'}</Button> : (

                        <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                    )}
                </Col>
            </Row>
            {(props.idx === 0 || open) && (
                <>
                    <Row className='spacing-md'>
                        <Col xs={12} sm={2}>
                            <Heading block number={4}>First Name</Heading>
                            <Body block>{firstName}</Body>
                        </Col>
                        <Col xs={12} sm={2}>
                            <Heading block number={4}>Last Name</Heading>
                            <Body block>{lastName}</Body>
                        </Col>
                        <Col xs={12} sm={3}>
                            <Heading block number={4}>Date of Birth</Heading>
                            <Body block>{moment(dob).format('L')}</Body>
                        </Col>
                        <Col xs={12} sm={3}>
                            <Heading block number={4}>Email Address</Heading>
                            <Body block>{email}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-md'>
                        <Col xs={12} sm={2}>
                            <Heading block number={4}>Phone Number</Heading>
                            <Body block>{phoneNumber}</Body>
                        </Col>
                        <Col xs={12} sm={2}>
                            <Heading block number={4}>State</Heading>
                            <Body block>{stateName}</Body>
                        </Col>
                        <Col xs={12} sm={2}>
                            <Heading block number={4}>Zip Code</Heading>
                            <Body block>{zipcode}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-md'>
                        <Col className='spacing-sm' sm={8}>
                            <Heading block number={4}>Specific Passion for studio filmmaking</Heading>
                            <Body block>{passion}</Body>
                        </Col>
                        <Col className='spacing-sm' sm={8}>
                            <Heading block number={4}>Creative collaboration on a Writing / Directing project</Heading>
                            <Body block>{collaborate}</Body>
                        </Col>
                        <Col className='spacing-sm' sm={8}>
                            <Heading block number={4}>Bio</Heading>
                            <Body block>{bio}</Body>
                        </Col>
                        <Col className='spacing-sm' sm={8}>
                            <Heading block number={4}>How did you hear about Launchpad?</Heading>
                            <Body block>{hearOption}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-md'>
                        <Col xs={12} sm={3}>
                            <Heading block number={3}>Uploads</Heading>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Resume</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <DownloadFile url={resume.url} filename={resume.filename} />
                        </Col>
                        {reference && (
                            <>
                                <Col xs={12} sm={3}>
                                    <Heading number={4} block>Professional References</Heading>
                                </Col>
                                <Col xs={12} sm={9}>
                                    <DownloadFile url={reference.url} filename={reference.filename} />
                                </Col>
                            </>
                        )}
                    </Row>
                    {/* <Row className='spacing-md'>
                        <Col xs={12}>
                            <Heading block number={3}>Demographics</Heading>
                            <Heading block number={4} className='capitalize'>{eeoText}</Heading>
                        </Col>
                    </Row> */}
                </>
            )}

            <hr className='Submission__divider' />

        </div>

    );
};

SubmissionApplicant.propTypes = {
    applicant: PropTypes.object,
    idx: PropTypes.number
};

export default SubmissionApplicant;
