import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Caption } from '../modules/Typography';
import Radio from '../modules/Radio';

const SubmissionRadioQuestion = ({ question, handleQuestion, error }) => {
    const [answer, setAnswer] = useState(question.answer);

    const handleChange = value => {
        setAnswer(value);
        handleQuestion({ id: question.id, value });
    };

    return (
        <div className='Submission__Question'>
            <Heading number={4}>{question.question}</Heading>
            <div className='Submission__Question__Radios'>
                {question.inputType.values.map(value => (
                    <Radio
                        key={`${question.id}-${value}`}
                        value={value}
                        name={value}
                        inline
                        padded={false}
                        selected={value === answer}
                        handleChange={handleChange}
                        className='Submission__Question__Radio'
                    />
                ))}
            </div>
            {error && <Caption number={2} error className='TextArea__error'>Please provide an answer to this question.</Caption>}
        </div>
    );
};

SubmissionRadioQuestion.propTypes = {
    handleQuestion: PropTypes.func,
    error: PropTypes.bool,
    question: PropTypes.object
};

export default SubmissionRadioQuestion;
