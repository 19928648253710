import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ApplicationStatus = props => {
    const [applicationStatus, setApplicationStatus] = useState('Completed');

    useEffect(() => {
        switch (props.status) {
        case 'NOT_STARTED':
            setApplicationStatus('Assigned');
            break;
        case 'COMPLETED':
            setApplicationStatus('Completed');
            break;
        case 'REJECTED':
            setApplicationStatus('Rejected');
            break;
        case 'ACCEPTED':
            setApplicationStatus('Accepted');
            break;
        default:
            setApplicationStatus('In Progress');
        }
    }, [props.status]);

    return (
        <div className={`Status ${props.className} ${props.status === 'NOT_STARTED' ? 'Status--faded' : ''} flex flex-start`}>

            <div className={`Status__outerCircle ${props.status === 'REJECTED' ? props.status : ''}`}>
                <div className={`Status__innerCircle ${props.status}`} />
            </div>
            <div className='Status__Text'>
                {applicationStatus}
            </div>
        </div>
    );
};

export default ApplicationStatus;

ApplicationStatus.propTypes = {
    /* classname */
    className: PropTypes.string,

    /* text of status to protray */
    status: PropTypes.string.isRequired
};

ApplicationStatus.defaultProps = {
    className: ''
};
