import React from 'react';
import PropTypes from 'prop-types';
import { Body } from './Typography';

const Tooltip = props => {
    return (
        <div className='Tooltip__wrapper'>
            <Body className='Tooltip' number={2}>{props.children}</Body>
        </div>
    );
};

export default Tooltip;

Tooltip.propTypes = { children: PropTypes.string };
