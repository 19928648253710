import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ASSIGN_REVIEWER, DELETE_REVIEW } from '../../queries';
import { SubmissionsContext } from '../pages/Submissions';
import Select from '../modules/Select';
import ConfirmationDialog from '../modules/ConfirmationDialog';
import { Body } from '../modules/Typography';
import Icon from '../modules/Icon';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';

const StageReviewer = ({ reviewerNumber, submissionId, ...props }) => {
    const { reviewers } = useContext(SubmissionsContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const [assigned, setAssigned] = useState(false);
    const [reviewer, setReviewer] = useState(props.reviewer);
    const [reviewId, setReviewId] = useState(props.reviewId);
    const [reviewerId, setReviewerId] = useState('');
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const [status, setStatus] = useState(props.status);
    const [assignReviewer] = useMutation(ASSIGN_REVIEWER);
    const [deleteReview] = useMutation(DELETE_REVIEW);

    const handleAssign = async ({ value }) => {
        const reviewer = reviewers.find(reviewer => reviewer.id === value);
        try {
            const response = await assignReviewer({
                variables: {
                    reviewerId: value,
                    submissionId,
                    reviewerNumber,
                    stage: props.stage
                }
            });
            setAssigned(true);
            setReviewerId(value);
            setTimeout(() => {
                setAssigned(false);
            }, 3000);
            const { stages } = response.data.assignReviewer;
            const newReview = stages[props.stage - 1].reviews.find(review => review.reviewerNumber === reviewerNumber);
            setReviewer(newReview.reviewer.name);
            setReviewId(newReview.id);
            setStatus(newReview.status);
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applicants from the current season may be reviewed' });
            } else {
                errorSnackbar({ text: `${reviewer.name} is already assigned to this submission` });
            }
        }
    };

    const handleRemove = async (e) => {
        try {
            e.stopPropagation();
            await deleteReview({ variables: { id: reviewId } });
            setReviewer('');
            setReviewerId('');
            setReviewId('');
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
        setLoading(false);
        setDialogOpen(false);
    };

    const handleDialog = e => {
        e.stopPropagation();
        setDialogOpen(true);
    };

    return (
        <div className={`StageReviewer ${props.className || ''} flex flex-start`} onClick={e => e.stopPropagation()}>
            {reviewer && (
                <div className={`StageReviewer__outerCircle ${reviewer ? status : 'UNASSIGNED'}`}>
                    <div className={`StageReviewer__innerCircle ${reviewer ? status : 'UNASSIGNED'}`} />
                </div>
            )}
            {reviewer ? (
                <div className='StageReviewer__Reviewer flex'>
                    <Body className='capitalize'>{reviewer}</Body>
                    <Icon name='trash-can' className='StageReviewer__Reviewer__icon info-text' onClick={handleDialog} />
                    <ConfirmationDialog
                        title='Remove Reviewer?'
                        text={`Are you sure you want to remove the reviewer ${reviewer} and reset the review?`}
                        confirmText='Remove'
                        open={dialogOpen}
                        onCancel={() => setDialogOpen(false)}
                        onConfirm={handleRemove}
                        loading={loading}
                    />
                </div>

            ) : (
                <div className={`StageReviewer__Select ${assigned ? 'flex center' : ''}`}>
                    <Select
                        className='StageReviewer__ReviewerName'
                        searchable
                        placeholder='Assign'
                        options={reviewers.map(reviewer => ({ text: reviewer.name || '', value: reviewer.id }))}
                        selectedValue={reviewerId}
                        onChange={handleAssign}
                    />
                    {assigned && (
                        <Icon name='check' className='StageReviewer__successText' />
                    )}
                </div>
            )}
        </div>
    );
};

StageReviewer.propTypes = {
    /* classname */
    className: PropTypes.string,

    /* text of status to protray */
    status: PropTypes.string.isRequired,

    /* text of the reviewer's name */
    reviewer: PropTypes.string,
    reviewId: PropTypes.string,

    /* Current stage */
    stage: PropTypes.number.isRequired,

    /* The number the reviewer will be for the submission */
    reviewerNumber: PropTypes.number.isRequired,

    /* Submission ID */
    submissionId: PropTypes.string
};

export default StageReviewer;
