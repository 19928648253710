import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SubmissionContext } from '../pages/Submission';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import IconButton from '../modules/IconButton';
import DownloadFile from '../modules/DownloadFile';

const SubmissionWritingSample = props => {
    const { genres, writingSampleTypes, allExpanded } = useContext(SubmissionContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    const { name, params, uploads } = props.sample;
    const title = params.find(param => param.key === 'title').value;
    const writer = params.find(param => param.key === 'writer').value;
    const logLine = params.find(param => param.key === 'logLine').value;
    const synopsis = params.find(param => param.key === 'synopsis').value;
    const year = params.find(param => param.key === 'year').value;
    const awards = params.find(param => param.key === 'awards').value;
    const genre = params.find(param => param.key === 'genre').value;
    const sampleType = params.find(param => param.key === 'sampleType').value;
    const pages = params.find(param => param.key === 'pages') ? params.find(param => param.key === 'pages').value : 'N/A';
    const pageLength = params.find(param => param.key === 'pageLength') ? params.find(param => param.key === 'pageLength').value : 'N/A';
    const genreText = genres.find(g => g.value === genre).text;
    const typeText = writingSampleTypes.find(g => g.value === sampleType) ? writingSampleTypes.find(g => g.value === sampleType).text : 'N/A';
    const script = uploads.find(upload => upload.name === 'writingSample');

    return (
        <div className='Submission__Applicant'>
            <Row>
                <Col xs={12} className='flex'>
                    <Heading number={2} className='capitalize'>{name}</Heading>
                    <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                </Col>
            </Row>
            {open && (
                <>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Script</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <DownloadFile url={script.url} filename={script.filename} />
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Sample type</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{typeText}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Sample genre</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{genreText}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Title</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{title}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Writer</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{writer}</Body>
                        </Col>
                    </Row>

                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Logline</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{logLine}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Synopsis</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{synopsis}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Year Created</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{year}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Awards</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{awards || 'N/A'}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Page Length</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{pageLength}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Best Pages to Read</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{pages}</Body>
                        </Col>
                    </Row>
                </>
            )}

            <hr className='Submission__divider' />

        </div>

    );
};

SubmissionWritingSample.propTypes = {
    sample: PropTypes.object
};

export default SubmissionWritingSample;
