import React from 'react';
import { Modal } from 'react-bootstrap';
import { Body } from '../modules/Typography';
import Button from '../modules/Button';
import logo from '../../images/launchpad-logo-blue.svg';

const PasswordUpdated = props => {
    return (
        <div className='ResetPassword'>
            <Modal show onHide={() => {}} className='ResetPassword__Modal'>
                <Modal.Header className='flex column align-center'>
                    <img src={logo} className='ResetPassword__Logo' />
                    <Modal.Title>Password Updated</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex column align-flex-start '>
                        <Body className='ResetPassword__Modal__subtitle'>You have successfully reset your password and can now use your new password to sign in.</Body>
                        <Body className=''>Please click below to Sign In to your account.</Body>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='btn-container sign-in'>
                        <Button block type='primary' link='/'>Sign In</Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default PasswordUpdated;
