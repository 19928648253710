import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import Button from './Button';
import Icon from './Icon';
import Typography from './Typography';
import IconButton from './IconButton';

const Search = props => {
    const [searchText, setApplicantText] = useState('');

    const handleButtonClick = () => {
        props.setApplicant(searchText);
    };

    const clearSearch = () => {
        setApplicantText('');
        props.setApplicant('');
    };

    return (
        <div className='Search'>
            <div className='Search__TextContainer'>
                <TextField
                    className='Search__TextField'
                    placeholder='eg. Applicant Name(s)'
                    value={searchText}
                    padded={false}
                    onChange={e => setApplicantText(e.target.value)}
                    onSubmit={handleButtonClick}
                />
                <Button
                    className='Search__TextField__Button'
                    type='tertiary'
                    color='dark-grey'
                    onClick={handleButtonClick}
                >
                    <Icon className='Search__TextField__Button__Icon' name='search' />
                </Button>
            </div>
            {searchText && (
                <IconButton name='close' size='small' onClick={clearSearch} />
            )}
            <Typography className='Search__Results' type='heading5'>Showing {props.offset + 1} to {(props.offset + props.limit < props.totalItems) ? (props.offset + props.limit) : props.totalItems} of {props.totalItems}</Typography>
        </div>
    );
};

export default Search;

Search.propTypes = {
    /* Number for which applicant we're starting with */
    offset: PropTypes.number.isRequired,

    /* Number for number of applicants received from query */
    limit: PropTypes.number.isRequired,

    /* Function to set the search text  */
    setApplicant: PropTypes.func.isRequired,

    /* Number of total applicants */
    totalItems: PropTypes.number.isRequired
};
