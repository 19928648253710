import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_QUESTION } from '../../queries';
import { Heading } from '../modules/Typography';
import { SubmissionContext } from '../pages/Submission';
import { ReviewContext } from './SubmissionReviews';
import SubmissionTextQuestion from './SubmissionTextQuestion';
import SubmissionRadioQuestion from './SubmissionRadioQuestion';
const SubmissionQuestions = props => {
    const { status } = useContext(SubmissionContext);
    const context = useContext(ReviewContext);
    const [questions, setQuestions] = useState(props.questions);
    let showErrors = false;
    if (context) {
        showErrors = context.showErrors;
    }
    const [updateQuestion] = useMutation(UPDATE_QUESTION);

    useEffect(() => {
        props.setQuestionsValid(!questions.find(question => !question.answer));
    }, [questions]);

    const handleQuestion = async ({ id, value }) => {
        const response = await updateQuestion({ variables: { id, value } });
        const review = response.data.updateQuestion.stages.find(stg => stg.reviews.length).reviews[0];
        const { questions } = review.sections[props.reviewIdx];
        setQuestions(questions);
        // props.setQuestionsValid(!review.sections[props.reviewIdx].questions.find(question => !question.answer));
    };

    return questions.map(question => {
        if (status !== 'COMPLETED' && !props.readOnly) {
            switch (question.inputType.type) {
            case 'radio':
                return (
                    <SubmissionRadioQuestion
                        question={question}
                        handleQuestion={handleQuestion}
                        key={question.id}
                        error={showErrors && !question.answer}
                    />
                );
            case 'text':
            default:
                return (
                    <SubmissionTextQuestion
                        question={question}
                        handleQuestion={handleQuestion}
                        key={question.id}
                        error={showErrors && !question.answer}
                    />
                );
            }
        }
        return (
            <div className='Submission__CompleteQuestion' key={question.id}>
                <Heading block number={4}>{question.question}</Heading>
                <Heading block number={4}>{question.answer}</Heading>
            </div>
        );
    });
};

export default SubmissionQuestions;
