import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import fetch from '../../utils/fetch';
import { Body, Caption } from '../modules/Typography';
import TextField from '../modules/TextField';
import Button from '../modules/Button';
import Icon from '../modules/Icon';
import { UserContext } from '../../contexts/UserContextProvider';
import logo from '../../images/launchpad-logo-blue.svg';
import PasswordUpdated from './PasswordUpdated';

const ResetPassword = props => {
    const { setToken } = useContext(UserContext);
    const { token } = useParams();
    const navigate = useNavigate();
    const [showPassword, setShow] = useState(false);
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setErrors([]);
    }, [password]);

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (password.length < 6) {
            setErrorMessage('Your password must be at least 6 characters.');
            setErrors(['password']);
        } else if (!/[A-Z]/.test(password)) {
            setErrorMessage('Your password must have uppercase characters.');
            setErrors(['password']);
        } else if (!/[a-z]/.test(password)) {
            setErrors(['password']);
        } else if (!/\d/.test(password)) {
            setErrorMessage('Your password must have numeric characters.');
            setErrors(['password']);
        } else {
            setErrors([]);
            setErrorMessage('');

            try {
                setLoading(true);
                const response = await fetch('/auth/sentry/reset-password', {
                    method: 'POST',
                    body: {
                        password,
                        token
                    }
                });
                if (response.error || response.errorCode) {
                    const errorDescription = response.error_description || response.message;
                    if (errorDescription === 'account not found') {
                        setErrorMessage('Account Not Found');
                        setErrors(['email']);
                    } else if (errorDescription.includes('Incorrect username or password')) {
                        setErrorMessage('Incorrect password');
                        setErrors(['password']);
                    } else if (errorDescription.includes('invalid token')) {
                        navigate('/forgot-password');
                    }
                } else {
                    setToken({ accessToken: response.access_token, refreshToken: response.refresh_token });
                    setSuccess(true);
                }
            } catch (err) {
                console.log('err', err);
            }
            setLoading(false);
        }
    };

    return success ? (
        <PasswordUpdated />
    )
        : (
            <div className='ResetPassword'>
                <Modal show onHide={() => {}} className='ResetPassword__Modal'>
                    <Modal.Header className='flex column align-center'>
                        <img src={logo} className='ResetPassword__Logo' />
                        <Modal.Title>Reset Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='flex column align-flex-start '>
                            <Body className='ResetPassword__Modal__subtitle'>Enter your new password below</Body>
                            <div className='flex align-center Login__Modal__password'>
                                <TextField
                                    className='ResetPassword__Modal__TextField right-10'
                                    padded={false}
                                    placeholder='Enter New Password'
                                    value={password}
                                    type={showPassword ? 'text' : 'password'}
                                    error={errors.includes('password')}
                                    errorMessage={errorMessage}
                                    onChange={e => setPassword(e.target.value)}
                                    handleKeyPress={handleKeyPress}
                                />
                                <Icon className='button Login__Modal__eye' name={`visibility-${showPassword ? 'off' : 'on'}`} onClick={() => setShow(!showPassword)} />
                            </div>
                        </div>
                        <Caption block number={2}>Your password must be between 8 - 32 characters long and include at least 3 of the following character types: uppercase letter (A-Z), lowercase letter (a-z) and a
                            number (0-9)
                        </Caption>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='btn-container'>
                            <Button block type='primary' disabled={errors.length || !password} loading={loading} onClick={handleSubmit}>Reset Password</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
};

export default ResetPassword;
