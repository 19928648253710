import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPDATE_REVIEW, ADD_TO_SHORTLIST } from '../../queries';
import { SubmissionContext } from '../pages/Submission';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { UserContext } from '../../contexts/UserContextProvider';
import { Heading, Caption } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import IconButton from '../modules/IconButton';
import Button from '../modules/Button';
import ConfirmationDialog from '../modules/ConfirmationDialog';
import SubmissionReview from './SubmissionReview';
import SubmissionOverallScoreCard from './SubmissionOverallScoreCard';

export const ReviewContext = React.createContext();

const SubmissionReviews = props => {
    const navigate = useNavigate();
    const { allExpanded, refetch, status, shortlisted, submissionId } = useContext(SubmissionContext);
    const { isMobile, documentWidth } = useContext(WidthContext);
    const { role } = useContext(UserContext);
    const [open, setOpen] = useState(true);
    const [isValid, setValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialog, setDialog] = useState('');
    const [overallScore, setOverallScore] = useState(props.review.overallScore);
    const [validReviews, setValidReviews] = useState([]);
    const [validOverall, setValidOverall] = useState(false);
    const [showErrors, setShowErrors] = useState(false);

    const [updateReview] = useMutation(UPDATE_REVIEW);
    const [addToShortList] = useMutation(ADD_TO_SHORTLIST);

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    const { sections, id } = props.review;

    useEffect(() => {
        setValid(validReviews.find(review => !review) === undefined && validOverall);
    }, [validOverall]);

    const handleReviewValid = ({ idx, valid }) => {
        validReviews[idx] = valid;
        setValidReviews(validReviews);
        setValid(validReviews.find(review => !review) === undefined && validOverall);
    };

    const handleSubmit = async () => {
        setLoading(true);
        await updateReview({ variables: { id, status: 'COMPLETED' } });
        setDialog('');
        setLoading(false);
        navigate('/submissions');
    };

    const handleEdit = async () => {
        setLoading(true);
        await updateReview({ variables: { id, status: 'IN_PROGRESS' } });
        setLoading(false);
        refetch();
    };

    const handleShortlist = async () => {
        try {
            setLoading(true);
            await addToShortList({ variables: { id: submissionId, shortlisted: !shortlisted } });
            refetch();
            setDialog('');
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };

    const handleSubmitDialog = () => {
        if (isValid) {
            setShowErrors(false);
            setDialog('submit');
        } else {
            setShowErrors(true);
        }
    };

    let title = 'Submit?';
    let dialogText = 'Submit changes or cancel and continue editing.';
    let confirmText = 'Submit';
    let confirmFunc = handleSubmit;

    if (dialog === 'shortlist') {
        title = shortlisted ? 'Remove from Shortlist?' : 'Add to Shortlist?';
        dialogText = '';
        confirmText = shortlisted ? 'Remove' : 'Add';
        confirmFunc = handleShortlist;
    }

    const columns = documentWidth < 1180 ? 12 : 6;
    const offset = documentWidth < 1180 ? 0 : 3;

    return (
        <ReviewContext.Provider value={{ overallScore, setOverallScore, setValidOverall, validOverall, showErrors, setShowErrors }}>
            <div className='Submission__Reviews'>
                <Row className='Submission__Reviews__Title'>
                    <Heading number={2}>Reviews</Heading>
                </Row>
                <Row>
                    <Col xs={12} className='flex'>
                        <Heading number={3} block className='Submission__Reviews__Subtitle capitalize'>Samples</Heading>
                        <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                    </Col>
                </Row>
                {open && (
                    <>
                        {sections.map((section, idx) => (
                            <SubmissionReview
                                review={section}
                                key={`section-${idx}`}
                                reviewIdx={idx}
                                handleReviewValid={handleReviewValid}
                            />
                        ))}
                        <Row>
                            <Col xs={12} lg={columns} lgOffset={offset}>
                                <SubmissionOverallScoreCard />
                                {status === 'COMPLETED' && (
                                    <Button
                                        type='secondary-outline'
                                        size='large'
                                        className='Submission__EditButton'
                                        onClick={handleEdit}
                                        loading={loading}
                                    >
                                        Edit Review
                                    </Button>
                                )}

                            </Col>
                        </Row>
                        <Row>
                            <Col xs={10} className='flex column align-flex-end'>
                                <div className={`Submission__Buttons flex ${isMobile ? '' : 'flex-end'}`}>
                                    {role === ('ADMIN' || 'SUPERADMIN') && (
                                        <Button
                                            className='Submission__Button'
                                            size='large'
                                            type='secondary-outline'
                                            onClick={() => setDialog('shortlist')}
                                            loading={loading}
                                        >{shortlisted ? 'Remove from Shortlist' : 'Add to Shortlist'}
                                        </Button>
                                    )}
                                    {status !== 'COMPLETED' && (
                                        <Button
                                            className='Submission__Button'
                                            size='large'
                                            type='primary'
                                            onClick={handleSubmitDialog}
                                            loading={loading}
                                        >Submit
                                        </Button>
                                    )}
                                </div>
                                {showErrors && <Caption number={2} error className='TextArea__error'>Please check your review for errors.</Caption>}
                            </Col>
                        </Row>
                    </>
                )}
                <ConfirmationDialog
                    title={title}
                    text={dialogText}
                    confirmText={confirmText}
                    open={!!dialog}
                    onCancel={() => setDialog('')}
                    onConfirm={confirmFunc}
                    loading={loading}
                />
            </div>
        </ReviewContext.Provider>
    );
};

SubmissionReviews.propTypes = {
    review: PropTypes.object
};
export default SubmissionReviews;
