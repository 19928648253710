import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { SYNC_REVIEWER } from '../queries';
import Login from '../components/pages/Login';

export const UserContext = React.createContext();

const UserContextProvider = ({ children }) => {
    const cookies = new Cookies();
    const location = useLocation();
    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);
    const [role, setRole] = useState('');
    const [season, setSeason] = useState(null);
    const [activeSeason, setActiveSeason] = useState('');
    const [syncReviewer] = useMutation(SYNC_REVIEWER);

    useEffect(() => {
        const accessToken = cookies.get('accessToken');
        const refreshToken = cookies.get('refreshToken');
        setToken({ accessToken, refreshToken });
    }, []);

    useEffect(() => {
        const fetchUser = async () => {
            if (token && token.accessToken && !user) {
                try {
                    const response = await syncReviewer();
                    setUser(response.data.syncReviewer);
                    setRole(response.data.syncReviewer.role);
                } catch (err) {
                    cookies.remove('accessToken');
                    cookies.remove('refreshToken');
                }
            }
        };
        fetchUser();
    }, [token]);

    let content = <Login />;
    if (user || location.pathname.includes('/register') || location.pathname === '/forgot-password' || location.pathname.includes('/reset-password')) {
        content = children;
    }

    return (
        <UserContext.Provider value={{ user, setUser, token, setToken, role, setRole, season, setSeason, activeSeason, setActiveSeason }}>
            {content}
        </UserContext.Provider>
    );
};

UserContextProvider.propTypes = {
    children: PropTypes.object
};

export default UserContextProvider;
