const fetch = async (url, options = {}) => {
    const headers = options.headers || {};
    if ((options.data || options.body) && !url.includes('kanvas')) {
        headers['content-type'] = 'application/json';
    }
    if (options.data && typeof options.data !== 'string') {
        options.data = JSON.stringify(options.data);
    } else if (options.body && typeof options.body !== 'string') {
        options.body = JSON.stringify(options.body);
    }
    options.headers = headers;
    options.credentials = 'same-origin';
    const makeRequest = async () => {
        const response = await window.fetch(url, options);
        return response;
    };
    const response = await makeRequest();
    let json;
    if (headers.accept === 'text/csv') {
        json = await response.text();
    } else if (headers.accept === 'application/xml') {
        json = response.text();
    } else if (headers.accept === 'file/xml' || url.includes('/api/email-exports') || url.includes('/api/download')) {
        json = await response.blob();
        // } else if ((response.type === 'cors' || response.status === 204) && !url.includes('kanvas')) {
        //     json = response;
    } else {
        json = await response.json();
    }
    return json;
};

export default fetch;
