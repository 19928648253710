import { gql } from '@apollo/client';

export const GET_REVIEWERS = gql`
  query reviewers($offset: Int!, $limit: Int!, $sort: String!, $sortBy: SortBy!, $name: String, $status: AccountStatus) {
    reviewers(
      pagination: { offset: $offset, limit: $limit }
      orderBy: { sort: $sort, sortBy: $sortBy }
      filters: { name: $name, status: $status }
    ) {
      items {
        ... on Reviewer {
          lastName
          firstName
          name
          id
          email
          role
          name
          status
          assignedApplications
          completedApplications
          created
          updated
          autoAssign
        }
      },
      offset
      limit
      totalItems
    }
  }
`;

export const ASSIGN_REVIEWER = gql`
  mutation assignReviewer($submissionId: String!, $stage: Int, $reviewerNumber: Int, $reviewerId: String!) {
    assignReviewer(id: $submissionId, stage: $stage, reviewerNumber: $reviewerNumber, reviewerId: $reviewerId) {
      id
      stages {
        stage 
        reviews {
          id
          reviewerNumber
          status
          reviewer{
            firstName
            lastName
            name
          }
        }
      }
    }
  }
`;

export const UPDATE_AUTOASSIGN = gql`
  mutation updateAutoAssign ($id: String! $canAutoAssign: Boolean!) {
    updateAutoAssign(id: $id, canAutoAssign: $canAutoAssign) {
      id
      autoAssign
    }
  }
`;
