import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import ScoreCard from './ScoreCard';
import SubmissionQuestions from './SubmissionQuestions';
import { ReviewContext } from './SubmissionReviews';

const SubmissionReview = props => {
    const { name, scores, questions, overallScore } = props.review;
    const [isValid, setValid] = useState(false);
    const [scoreValid, setScoreValid] = useState(false);
    const [questionsValid, setQuestionsValid] = useState(false);
    const { isMobile, size } = useContext(WidthContext);
    let showErrors = false;
    const context = useContext(ReviewContext);
    if (context) {
        showErrors = context.showErrors;
    }

    useEffect(() => {
        setValid(scoreValid && questionsValid);
    }, [scoreValid, questionsValid]);

    useEffect(() => {
        if (!props.readOnly) {
            props.handleReviewValid({ idx: props.reviewIdx, valid: isValid });
        }
    }, [isValid]);

    let columns = 6;
    if (isMobile || size === 'md') {
        columns = 5;
    } else if (size === 'xl' || size === 'xxl') {
        columns = 3;
    }

    return (
        <div className='Submission__Review'>
            <Row className='flex'>
                <Col xs={12}>
                    <Heading number={3} block className=' Submission__Review__Title capitalize'>{name}</Heading>
                </Col>
            </Row>
            {isMobile ? (
                <>
                    <Row>
                        <Col lg={columns}>
                            <ScoreCard
                                scores={scores}
                                sectionScore={overallScore}
                                reviewIdx={props.reviewIdx}
                                setScoreValid={setScoreValid}
                                readOnly={props.readOnly}
                                error={showErrors && !scoreValid}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={5}>
                            <SubmissionQuestions
                                readOnly={props.readOnly}
                                questions={questions}
                                reviewIdx={props.reviewIdx}
                                setQuestionsValid={setQuestionsValid}
                            />
                        </Col>
                    </Row>
                </>
            ) : (
                <Row>
                    <Col lg={columns}>
                        <ScoreCard
                            scores={scores}
                            sectionScore={overallScore}
                            reviewIdx={props.reviewIdx}
                            setScoreValid={setScoreValid}
                            readOnly={props.readOnly}
                            error={showErrors && !scoreValid}
                        />
                    </Col>
                    <Col lg={5} lgOffset={1}>
                        <SubmissionQuestions
                            readOnly={props.readOnly}
                            questions={questions}
                            reviewIdx={props.reviewIdx}
                            setQuestionsValid={setQuestionsValid}
                        />
                    </Col>
                </Row>
            )}
        </div>
    );
};

SubmissionReview.propTypes = {
    handleReviewValid: PropTypes.func,
    reviewIdx: PropTypes.number,
    readOnly: PropTypes.bool,
    review: PropTypes.object
};

export default SubmissionReview;
