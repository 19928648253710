import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SEND_INVITES } from '../../queries';
import DialogWrapper from '../modules/DialogWrapper';
import TextArea from '../modules/TextArea';

const InviteReviewers = props => {
    const [invites, setInvites] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [sendInvites] = useMutation(SEND_INVITES);

    const testEmail = (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(value);
    };

    const handleInvites = (e) => {
        setInvites(e.target.value.split(',').map(invite => invite.trim().toLowerCase()));
    };

    const handleConfirm = async () => {
        const errors = invites.reduce((prev, curr) => {
            if (!testEmail(curr)) {
                return [...prev, curr];
            }
            return prev;
        }, []);
        if (errors.length) {
            setErrorMessage(`Please check the following email${errors.length !== 1 ? 's' : ''}: ${errors.join(', ')}`);
            setError(true);
        } else {
            try {
                setError(false);
                setErrorMessage('');
                setLoading(true);
                await sendInvites({
                    variables: {
                        emails: invites,
                        role: props.role,
                        provider: props.type === 'email' ? 'EMAIL' : 'MYID'
                    }
                });
                setLoading(false);
                setInvites([]);
                props.setOpen(false);
                props.setInviteSent(!props.inviteSent);
            } catch (err) {
                console.log(err);
                setError(true);
                setLoading(false);
                setErrorMessage('Email(s) could not be sent. Please try again later.');
            }
        }
    };

    const handleCancel = () => {
        props.setOpen(false);
        setInvites([]);
        setError(false);
        setErrorMessage('');
    };

    const subtitle = props.type.charAt(0).toUpperCase() + props.type.slice(1) + ' (separate by commas)';

    return (
        <DialogWrapper
            open={props.open}
            padded
            closeOnEscape
            title={'Invite ' + props.role.slice(0, 1) + props.role.slice(1).toLowerCase()}
            subtitle={subtitle}
            confirmText='Submit'
            className='InviteReviewers'
            loading={loading}
            heightSize='medium'
            size='medium'
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        >
            <TextArea
                onChange={handleInvites}
                value={invites.join(', ')}
                error={error}
                errorMessage={errorMessage}
            />
        </DialogWrapper>
    );
};

InviteReviewers.propTypes = {
    type: PropTypes.string,
    role: PropTypes.string,
    setOpen: PropTypes.func,
    inviteSent: PropTypes.bool,
    setInviteSent: PropTypes.func,
    open: PropTypes.bool
};

export default InviteReviewers;
