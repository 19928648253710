import React, { useState, useContext } from 'react';
// import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { GET_SUBMISSION } from '../../queries';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { UserContext } from '../../contexts/UserContextProvider';
import Loading from '../modules/Loading';
import { Container } from '../modules/Grid';
import submissionConfig from '../submissionDetail/submissionConfig';
import SubmissionApplicant from '../submissionDetail/SubmissionApplicant';
import SubmissionScript from '../submissionDetail/SubmissionScript';
import SubmissionDirectingSample from '../submissionDetail/SubmissionDirectingSample';
import SubmissionWritingSample from '../submissionDetail/SubmissionWritingSample';
import SubmissionLegal from '../submissionDetail/SubmissionLegal';
import SubmissionReviews from '../submissionDetail/SubmissionReviews';
import AdminSubmissionReviews from '../submissionDetail/AdminSubmissionReviews';

export const SubmissionContext = React.createContext();

const Submission = props => {
    const { id } = useParams();
    const { role } = useContext(UserContext);
    const { data, loading, refetch } = useQuery(GET_SUBMISSION, { variables: { id } });
    const [allExpanded, setExpanded] = useState(false);
    if (loading) return <Loading />;
    const { hearOptions, states, sampleTypes, writingSampleTypes, genres, genders, ethnicities, races, veterans, disabilities, employees } = submissionConfig;
    const { applicants, uploads, samples, stages, currentStage, shortlisted } = data.submission;
    const stage = stages[0];
    const review = stage.reviews[0];
    const { eligible, recommended, reviewable, overallScore, status } = review || {};
    const script = uploads.find(upload => upload.name === 'script');

    return (
        <SubmissionContext.Provider value={{
            hearOptions,
            states,
            sampleTypes,
            writingSampleTypes,
            genres,
            genders,
            ethnicities,
            races,
            disabilities,
            veterans,
            employees,
            recommended,
            overallScore,
            currentStage,
            eligible,
            reviewable,
            allExpanded,
            setExpanded,
            status,
            refetch,
            stages,
            shortlisted,
            submissionId: data.submission.id,
            reviewId: review ? review.id : ''

        }}
        >
            <div className='Submission'>
                <Helmet><title>Launchpad Back Office | Submission</title></Helmet>
                <Container>
                    {applicants.map((applicant, idx) => (
                        <SubmissionApplicant applicant={applicant} idx={idx} key={`applicant-${idx}`} />
                    ))}
                    {script ? <SubmissionScript script={script} /> : ''}
                    {samples.map((sample, idx) => (
                        sample.params &&
                       (sample.name.includes('Directing') ? (
                           <SubmissionDirectingSample sample={sample} key={`sample-${idx}`} />
                       ) : (
                           <SubmissionWritingSample sample={sample} key={`sample-${idx}`} />
                       )
                       )))}
                    <SubmissionLegal applicants={applicants} />
                    {role.toLowerCase() === 'reviewer' ? (
                        <SubmissionReviews review={review} />
                    ) : (
                        <AdminSubmissionReviews stages={stages} />

                    )}
                </Container>
            </div>
        </SubmissionContext.Provider>
    );
};

Submission.propTypes = {

};

export default Submission;
