import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../modules/Typography';

const DashboardCardSet = props => {
    return (
        <div className='Dashboard__CardSet'>
            <Heading number={2} block className='Dashboard__CardSet__title'>{props.title}</Heading>
            <div className='flex flex-start'>
                {props.cards.map(card => (
                    <div className={`Dashboard__Card flex column ${card.color ? `Dashboard__Card--${card.color}` : ''}`} key={card.name}>
                        <Heading number={2} className='Dashboard__Card__number'>{card.number || 0}</Heading>
                        <Heading number={5} className='Dashboard__Card__name'>{card.name}</Heading>
                    </div>
                ))}
            </div>
        </div>
    );
};

DashboardCardSet.propTypes = {
    title: PropTypes.string,
    cards: PropTypes.array
};

export default DashboardCardSet;
