import React, { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_REVIEW } from '../../queries';
import { Body, Caption } from '../modules/Typography';
import ScoreButton from './ScoreButton';
import { SubmissionContext } from '../pages/Submission';
import { ReviewContext } from './SubmissionReviews';
import Radio from '../modules/Radio';

const SubmissionOverallScoreCard = props => {
    const didMount = useRef(false);
    const { eligible, reviewable, recommended, reviewId, status, currentStage } = useContext(SubmissionContext);
    const context = useContext(ReviewContext);
    let overallScore = props.overallScore;
    let setValidOverall;
    let validOverall;
    let showErrors;

    if (context) {
        ({ overallScore, setValidOverall, validOverall, showErrors } = context);
    }
    const [stateRecommended, setStateRecommended] = useState(recommended);
    const [stateReviewable, setStateReviewable] = useState(reviewable);
    const [stateEligible, setStateEligible] = useState(eligible);
    const [updateReview] = useMutation(UPDATE_REVIEW);

    useEffect(() => {
        if (!props.isAdmin) {
            if (didMount.current) {
                updateReview({ variables: { id: reviewId, eligible: stateEligible, reviewable: stateReviewable, recommended: stateRecommended } });
            } else {
                didMount.current = true;
            }
            setValidOverall(stateRecommended !== null && stateEligible !== null && stateReviewable !== null);
        }
    }, [stateRecommended, stateReviewable, stateEligible]);

    return (
        <>
            <div className={`SubmissionOverallScoreCard ScoreCard ${props.isAdmin ? 'AdminOverallScoreCard' : ''}`}>
                <div className='SubmissionOverallScoreCard__Section flex'>
                    <Body className='SubmissionOverallScoreCard__Section__Name'>Overall Recommendation</Body>
                    {status === 'COMPLETED' ? (
                        <div className={`ScoreCard__Button ScoreCard__Button__number ScoreCard__Button__number__${recommended ? '5' : '0'}`}>
                            <Body className='ScoreCard__Button__Icon ScoreCard__Button__Icon__letter'>{recommended ? 'Y' : 'N'}</Body>
                        </div>

                    ) : (
                        <div className='SubmissionOverallScoreCard__Radios flex'>
                            <Radio
                                padded={false}
                                selected={stateRecommended}
                                name='Yes'
                                value='true'
                                handleChange={() => setStateRecommended(true)}
                            />
                            <Radio
                                padded={false}
                                selected={stateRecommended === false}
                                name='No'
                                value='false'
                                handleChange={() => setStateRecommended(false)}
                            />
                        </div>

                    )}
                </div>
                {currentStage <= 2 &&
                    <div className='SubmissionOverallScoreCard__Section flex'>
                        <Body className='SubmissionOverallScoreCard__Section__Name'>Overall Score</Body>
                        <ScoreButton score={overallScore !== null ? overallScore.toFixed(1) : -1} />
                    </div>}
                <div className='SubmissionOverallScoreCard__Section flex'>
                    <Body className='SubmissionOverallScoreCard__Section__Name'>Eligibility</Body>
                    {status === 'COMPLETED' ? (
                        <div className={`ScoreCard__Button ScoreCard__Button__number ScoreCard__Button__number__${eligible ? '5' : '0'}`}>
                            <Body className='ScoreCard__Button__Icon ScoreCard__Button__Icon__letter'>{eligible ? 'Y' : 'N'}</Body>
                        </div>

                    ) : (
                        <div className='SubmissionOverallScoreCard__Radios flex'>
                            <Radio
                                padded={false}
                                selected={stateEligible}
                                name='Yes'
                                value='true'
                                handleChange={() => setStateEligible(true)}
                            />
                            <Radio
                                padded={false}
                                selected={stateEligible === false}
                                name='No'
                                value='false'
                                handleChange={() => setStateEligible(false)}
                            />
                        </div>
                    )}
                </div>
                <div className='SubmissionOverallScoreCard__Section flex'>
                    <Body className='SubmissionOverallScoreCard__Section__Name'>Able to Review</Body>
                    {status === 'COMPLETED' ? (
                        <div className={`ScoreCard__Button ScoreCard__Button__number ScoreCard__Button__number__${reviewable ? '5' : '0'}`}>
                            <Body className='ScoreCard__Button__Icon ScoreCard__Button__Icon__letter'>{reviewable ? 'Y' : 'N'}</Body>
                        </div>

                    ) : (
                        <div className='SubmissionOverallScoreCard__Radios flex'>
                            <Radio
                                padded={false}
                                selected={stateReviewable}
                                name='Yes'
                                value='true'
                                handleChange={() => setStateReviewable(true)}
                            />
                            <Radio
                                padded={false}
                                selected={stateReviewable === false}
                                name='No'
                                value='false'
                                handleChange={() => setStateReviewable(false)}
                            />
                        </div>
                    )}
                </div>
            </div>
            {showErrors && !validOverall && <Caption number={2} error className='TextArea__error'>Please fill out all the above values.</Caption>}
        </>
    );
};

SubmissionOverallScoreCard.propTypes = {
    overallScore: PropTypes.number,
    isAdmin: PropTypes.bool
};

export default SubmissionOverallScoreCard;
