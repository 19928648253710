import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { GET_DASHBOARD } from '../../queries';
import Paper from '../modules/Paper';
import Loading from '../modules/Loading';
import DashboardCards from '../dashboard/DashboardCards';
import DashboardTables from '../dashboard/DashboardTables';
import { UserContext } from '../../contexts/UserContextProvider';

export const DashboardContext = React.createContext();

const Dashboard = props => {
    const navigate = useNavigate();
    const { role, season } = useContext(UserContext);
    const [getDashboard, { data, loading }] = useLazyQuery(GET_DASHBOARD, { variables: { seasonId: season } });

    useEffect(() => {
        if (role === 'REVIEWER') {
            navigate('/submissions');
        }
    }, []);

    useEffect(() => {
        if (role === 'REVIEWER') {
            navigate('/submissions');
        } else if (role === ('ADMIN' || 'SUPERADMIN')) {
            getDashboard();
        }
    }, [role]);

    useEffect(() => {
        getDashboard();
    }, [season]);

    if (loading) return <Loading />;

    const {
        applicationRoles,
        applicationStatsByStage,
        applicationStepsCompleted,
        applicationTotalStats,
        reviewsCompletedByReviewer,
        applicantsByState,
        applicantsByHeard,
        applicantsByRace,
        applicantsByGender,
        applicantsByEthnicity,
        applicantsByVeteranStatus,
        applicantsByDisability,
        applicantsEmployedByDisney
    } = data?.metrics || [];

    return (
        <DashboardContext.Provider value={{
            applicationRoles,
            applicationStatsByStage,
            applicationStepsCompleted,
            applicationTotalStats,
            reviewsCompletedByReviewer,
            applicantsByState,
            applicantsByHeard,
            applicantsByRace,
            applicantsByGender,
            applicantsByEthnicity,
            applicantsByVeteranStatus,
            applicantsByDisability,
            applicantsEmployedByDisney
        }}
        >
            <div className='Dashboard'>
                <Helmet><title>Launchpad Back Office | Dashboard</title></Helmet>
                <Paper className='Dashboard__Paper'>
                    <DashboardCards />
                    <DashboardTables />
                </Paper>
            </div>
        </DashboardContext.Provider>
    );
};

export default Dashboard;
