import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { SubmissionContext } from '../pages/Submission';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import IconButton from '../modules/IconButton';
import Button from '../modules/Button';
import PdfViewer from '../modules/PdfViewer';

const SubmissionScript = props => {
    const { genres, allExpanded } = useContext(SubmissionContext);
    const [open, setOpen] = useState(false);
    const { id } = useParams();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    const { filename, params, url: scriptUrl } = props.script;
    const genre = params.find(param => param.key === 'genre').value;
    const genreText = genres.find(option => option.value === genre).text;
    const writtenBy = params.find(param => param.key === 'writtenBy').value;
    let scriptWrittenBy = 'Me';
    if (writtenBy === 'other') {
        scriptWrittenBy = 'Another writer and me.';
    } else if (writtenBy === 'team') {
        scriptWrittenBy = 'Another Writing Team';
    }

    const handleScript = () => {
        navigate(`/submissions/${id}/script`);
    };

    const handleClose = () => {
        navigate(`/submissions/${id}`);
    };

    return (
        <div className='Submission__Applicant'>
            <Row>
                <Col xs={12} className='flex'>
                    <Heading number={2} className='capitalize'>Script</Heading>
                    <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                </Col>
            </Row>
            {pathname.includes('script') &&
            <PdfViewer file={scriptUrl} filename={filename} handleClose={handleClose} />}
            {open && (
                <>
                    <Row>
                        <Col xs={12} sm={2}>
                            <Heading number={4} block>Script</Heading>
                        </Col>
                        <Col xs={12} sm={10}>
                            <Button type='tertiary' onClick={handleScript} icon='outward-link'>{filename}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={2}>
                            <Heading number={4} block>Script Author</Heading>
                        </Col>
                        <Col xs={12} sm={10}>
                            <Body>{scriptWrittenBy}</Body>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={2}>
                            <Heading number={4} block>Script Genre</Heading>
                        </Col>
                        <Col xs={12} sm={10}>
                            <Body>{genreText}</Body>
                        </Col>
                    </Row>
                </>
            )}

            <hr className='Submission__divider' />

        </div>

    );
};

SubmissionScript.propTypes = {
    script: PropTypes.object
};

export default SubmissionScript;
