import { gql } from '@apollo/client';

export const GET_SEASON = gql`
  query season($id: String!) {
    season(id: $id) {
      id
      seasonNumber
      year
      activeSeason
    }
  }
`;

export const GET_SEASONS = gql`
  query seasons {
    seasons {
      id
      seasonNumber
      year
      activeSeason
    }
  }
`;

export const UPDATE_SEASON = gql`
  mutation updateSeason($id: String!, $year: Int, $activeSeason: Boolean) { 
    updateSeason(id: $id, season: {year: $year, activeSeason: $activeSeason}) {
      id
      seasonNumber
      year
      activeSeason
    }
  }
`;

export const ADD_SEASON = gql`
  mutation addSeason($year: Int, $activeSeason: Boolean) {
    addSeason(season: {year: $year, activeSeason: $activeSeason}) {
      id
      seasonNumber
      year
      activeSeason
    }
  }
`;
