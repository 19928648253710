import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page } from 'react-pdf/dist/umd/entry.webpack';
import fetch from '../../utils/fetch';
import IconButton from '../modules/IconButton';
import Loading from '../modules/Loading';
import { Body } from '../modules/Typography';
// import testFile from '../../images/testFile.pdf';

const PdfViewer = props => {
    const [file, setFile] = useState(null);
    // const [pageNumber, setPageNumber] = useState(1);
    // const pageNumber = 1;
    const [pages, setPages] = useState(0);
    const [scaleIdx, setScaleIdx] = useState(4);
    const scales = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 2];

    useEffect(() => {
        document.body.classList.add('no-scroll');
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, []);

    useEffect(() => {
        const downloadPdf = async () => {
            const blob = await fetch(`/api/download?fileUrl=${props.file}`);
            const file = new File([blob], props.filename);
            setFile(file);
        };
        downloadPdf();
    }, [props.file]);

    const handleZoomIn = () => {
        if (scaleIdx < scales.length - 2) {
            setScaleIdx(scaleIdx + 1);
        }
    };
    const handleZoomOut = () => {
        if (scaleIdx > 1) {
            setScaleIdx(scaleIdx - 1);
        }
    };

    const handleLoadSuccess = ({ numPages }) => {
        setPages(numPages);
    };
    const handleError = (err) => {
        console.log('err', err);
    };

    return (
        <div className='PdfViewer'>
            <div className='PdfViewer__Header flex'>
                <div>
                    <IconButton className='PdfViewer__button' name='zoom-out' onClick={handleZoomOut} />
                    <IconButton className='PdfViewer__button' name='zoom-in' onClick={handleZoomIn} />
                </div>
                <Body>{props.filename}</Body>
                <IconButton className='PdfViewer__button__close' name='close' onClick={props.handleClose} />
            </div>
            <div className='PdfViewer__content'>
                <div className='PdfViewer__container' style={{ width: 1000 * scales[scaleIdx] }}>
                    <Document
                        file={file}
                        onLoadSuccess={handleLoadSuccess}
                        onLoadError={handleError}
                        className='PdfViewer__Document'
                        loading={<Loading />}
                        noData='Loading...'
                    >

                        {Array.from(
                            new Array(pages),
                            (el, index) => (
                                <Page
                                    key={`page_${index + 1}`}
                                    pageNumber={index + 1}
                                    className='PdfViewer__Page'
                                    scale={scales[scaleIdx]}
                                    width={1000}
                                />
                            )
                        )}
                    </Document>
                </div>
            </div>
        </div>
    );
};

PdfViewer.propTypes = {
    file: PropTypes.string,
    filename: PropTypes.string,
    handleClose: PropTypes.func
};

export default PdfViewer;
