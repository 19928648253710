import React from 'react';
import WidthContextProvider from '../contexts/WidthContextProvider';
import SnackbarContextProvider from '../contexts/SnackbarContextProvider';
import UserContextProvider from '../contexts/UserContextProvider';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '../styles/main.scss';

import GraphiqlEditor from './GraphiqlEditor';
import Dashboard from './pages/Dashboard';
import Registration from './pages/Registration';
import ForgotPassword from './login/ForgotPassword';
import Layout from './layout/Layout';
import Exports from './pages/Exports';
import ManageSeasons from './pages/ManageSeasons';
import ManageReviewers from './pages/ManageReviewers';
import Submissions from './pages/Submissions';
import Submission from './pages/Submission';
import ResetPassword from './login/ResetPassword';

const routes = [
    { path: '/exports', component: Exports, title: 'Exports', isAdmin: true },
    { path: '/manage-seasons', component: ManageSeasons, title: 'Manage Seasons', isAdmin: true },
    { path: '/dashboard', component: Dashboard, title: 'Dashboard', isAdmin: true },
    { path: '/manage-reviewers', component: ManageReviewers, title: 'Manage Reviewers', isAdmin: true },
    { path: '/submissions', component: Submissions, title: 'Submissions' },
    { path: '/submissions/stage/1', component: Submissions, title: 'Submissions: Stage 1', isAdmin: true },
    { path: '/submissions/stage/2', component: Submissions, title: 'Submissions: Stage 2', isAdmin: true },
    { path: '/submissions/stage/3', component: Submissions, title: 'Submissions: Stage 3', isAdmin: true },
    { path: '/submissions/stage/4', component: Submissions, title: 'Submissions: Stage 4', isAdmin: true },
    { path: '/submissions/:id', component: Submission, title: 'Submissions' },
    { path: '/submissions/:id/script', component: Submission, title: 'Script' }

];

function App () {
    return (
        <BrowserRouter>
            <WidthContextProvider>
                <SnackbarContextProvider>
                    <UserContextProvider>
                        <Routes>
                            <Route path='/register/:sentryToken' element={<Registration />} />
                            <Route path='/forgot-password' element={<ForgotPassword />} />
                            <Route path='/reset-password/:token' element={<ResetPassword />} />
                            <Route path='/graphiql' element={<GraphiqlEditor />} />

                            {routes.map((route, key) => {
                                const { path, component: Component, ...props } = route;

                                return (
                                    <Route
                                        key={key}
                                        path={path}
                                        element={
                                            <Layout {...props}>
                                                <Component />
                                            </Layout>
                                        }
                                    />
                                );
                            })}
                            <Route path='*' element={<Navigate to='/dashboard' />} />
                        </Routes>
                    </UserContextProvider>
                </SnackbarContextProvider>
            </WidthContextProvider>
        </BrowserRouter>
    );
}

export default App;
