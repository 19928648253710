const submissionConfig = {
    hearOptions: [
        {
            text: 'Digital advertisement',
            value: 'ad'
        },
        {
            text: 'Disney employee',
            value: 'employee'
        },
        {
            text: 'Film school',
            value: 'school'
        },
        {
            text: 'News article',
            value: 'article'
        },
        {
            text: 'Organization',
            value: 'organization'
        },
        {
            text: 'Social Media',
            value: 'social'
        },
        {
            text: 'Word of mouth',
            value: 'mouth'
        },
        {
            text: 'Other',
            value: 'other'
        }
    ],
    states: [
        {
            text: 'Alabama',
            value: 'AL'
        },
        {
            text: 'Alaska',
            value: 'AK'
        },
        {
            text: 'Arkansas',
            value: 'AR'
        },
        {
            text: 'Arizona',
            value: 'AZ'
        },
        {
            text: 'California',
            value: 'CA'
        },
        {
            text: 'Colorado',
            value: 'CO'
        },
        {
            text: 'Connecticut',
            value: 'CT'
        },
        {
            text: 'District of Columbia',
            value: 'DC'
        },
        {
            text: 'Delaware',
            value: 'DE'
        },
        {
            text: 'Florida',
            value: 'FL'
        },
        {
            text: 'Georgia',
            value: 'GA'
        },
        {
            text: 'Hawaii',
            value: 'HI'
        },
        {
            text: 'Iowa',
            value: 'IA'
        },
        {
            text: 'Idaho',
            value: 'ID'
        },
        {
            text: 'Illinois',
            value: 'IL'
        },
        {
            text: 'Indiana',
            value: 'IN'
        },
        {
            text: 'Kansas',
            value: 'KS'
        },
        {
            text: 'Kentucky',
            value: 'KY'
        },
        {
            text: 'Louisiana',
            value: 'LA'
        },
        {
            text: 'Massachusetts',
            value: 'MA'
        },
        {
            text: 'Maryland',
            value: 'MD'
        },
        {
            text: 'Maine',
            value: 'ME'
        },
        {
            text: 'Michigan',
            value: 'MI'
        },
        {
            text: 'Minnesota',
            value: 'MN'
        },
        {
            text: 'Missouri',
            value: 'MO'
        },
        {
            text: 'Mississippi',
            value: 'MS'
        },
        {
            text: 'Montana',
            value: 'MT'
        },
        {
            text: 'North Carolina',
            value: 'NC'
        },
        {
            text: 'North Dakota',
            value: 'ND'
        },
        {
            text: 'Nebraska',
            value: 'NE'
        },
        {
            text: 'New Hampshire',
            value: 'NH'
        },
        {
            text: 'New Jersey',
            value: 'NJ'
        },
        {
            text: 'New Mexico',
            value: 'NM'
        },
        {
            text: 'Nevada',
            value: 'NV'
        },
        {
            text: 'New York',
            value: 'NY'
        },
        {
            text: 'Oklahoma',
            value: 'OK'
        },
        {
            text: 'Ohio',
            value: 'OH'
        },
        {
            text: 'Oregon',
            value: 'OR'
        },
        {
            text: 'Pennsylvania',
            value: 'PA'
        },
        {
            text: 'Rhode Island',
            value: 'RI'
        },
        {
            text: 'South Carolina',
            value: 'SC'
        },
        {
            text: 'South Dakota',
            value: 'SD'
        },
        {
            text: 'Tennessee',
            value: 'TN'
        },
        {
            text: 'Texas',
            value: 'TX'
        },
        {
            text: 'Utah',
            value: 'UT'
        },
        {
            text: 'Virginia',
            value: 'VA'
        },
        {
            text: 'Vermont',
            value: 'VT'
        },
        {
            text: 'Washington',
            value: 'WA'
        },
        {
            text: 'Wisconsin',
            value: 'WI'
        },
        {
            text: 'West Virginia',
            value: 'WV'
        },
        {
            text: 'Wyoming',
            value: 'WY'
        }
    ],
    sampleTypes: [
        {
            text: 'Branded Content',
            value: 'branded'
        },
        {
            text: 'Commercial',
            value: 'commercial'
        },
        {
            text: 'Documentary',
            value: 'documentary'
        },
        {
            text: 'Music Video',
            value: 'music'
        },
        {
            text: 'Scripted, narrative episode',
            value: 'episode'
        },
        {
            text: 'Scripted, narrative feature/made-for-tv/streaming film',
            value: 'feature'
        },
        {
            text: 'Scripted, narrative short film',
            value: 'short'
        },
        {
            text: 'Scripted, narrative webisode',
            value: 'webisode'
        },
        {
            text: 'Unscripted reality show',
            value: 'reality'
        },
        {
            text: 'Other',
            value: 'other'
        }
    ],
    writingSampleTypes: [
        {
            text: 'Feature',
            value: 'feature'
        },
        {
            text: 'Play',
            value: 'play'
        },
        {
            text: 'Short Film',
            value: 'short'
        },
        {
            text: 'TV Pilot',
            value: 'pilot'
        }
    ],
    genres: [
        {
            text: 'Action & Adventure',
            value: 'action'
        },
        {
            text: 'Comedy',
            value: 'comedy'
        },
        {
            text: 'Coming-of-Age',
            value: 'comingOfAge'
        },
        {
            text: 'Drama',
            value: 'drama'
        },
        {
            text: 'Dramedy',
            value: 'dramedy'
        },
        {
            text: 'Fantasy',
            value: 'fantasy'
        },
        {
            text: 'Horror',
            value: 'horror'
        },
        {
            text: 'Musical',
            value: 'musical'
        },
        {
            text: 'Mystery/Thriller',
            value: 'mystery'
        },
        {
            text: 'Sci-fi',
            value: 'scifi'
        },
        {
            text: 'Other',
            value: 'other'
        }
    ],
    genders: [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Opted out', value: 'optedOut' },
        { text: 'Non-binary', value: 'nonBinary' }
    ],
    ethnicities: [
        { text: 'No (Not Hispanic or Latino)', value: 'notHispanic' },
        { text: 'Opted out', value: 'optedOut' },
        { text: 'Yes (Identify as Hispanic of Latino/Latina/Latinx)', value: 'hispanic' }

    ],
    races: [
        { text: 'Native Hawaiian or other Pacific Islander (Not Hispanic or Latino)', value: 'pacific' },
        { text: 'Asian (Not Hispanic or Latino)', value: 'asian' },
        { text: 'Black or African American (Not Hispanic or Latino)', value: 'black' },
        { text: 'American Indian or Alaskan Native (Not Hispanic or Latino)', value: 'indian' },
        { text: 'White (Not Hispanic or Latino)', value: 'white' },
        { text: 'Two or More Races (Not Hispanic or Latino)', value: 'two' },
        { text: 'Do not wish to disclose', value: 'noDisclose' },
        { text: 'Opted out', value: 'optedOut' }

    ],
    veterans: [
        { text: 'Veteran', value: 'veteran' },
        { text: 'Not a veteran', value: 'notVeteran' },
        { text: 'Opted out', value: 'optedOut' },
        { text: 'Do not wish to disclose', value: 'noDisclose' }
    ],
    disabilities: [
        { text: 'Has a disability', value: 'disability' },
        { text: 'No disability', value: 'noDisability' },
        { text: 'Opted out', value: 'optedOut' },
        { text: 'Do not wish to disclose', value: 'noDisclose' }
    ],
    employees: [
        { text: 'Disney or affiliate employee', value: 'yes' },
        { text: 'Opted out', value: 'optedOut' },
        { text: 'Not a Disney or affiliate employee', value: 'no' }
    ]

};
export default submissionConfig;
