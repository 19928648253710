import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ASSIGN_REVIEWER, DELETE_REVIEW } from '../../queries';
import { SnackbarContext } from '../../contexts/SnackbarContextProvider';
import { ReviewContext } from './AdminSubmissionReviews';
import { SubmissionContext } from '../pages/Submission';
import { Heading, Body } from '../modules/Typography';
import Select from '../modules/Select';
import Icon from '../modules/Icon';
import Button from '../modules/Button';
import ConfirmationDialog from '../modules/ConfirmationDialog';
import ApplicationStatus from '../applicationAssignments/ApplicationStatus';

const AssignReviewer = props => {
    const { reviewers } = useContext(ReviewContext);
    const { errorSnackbar } = useContext(SnackbarContext);
    const { submissionId } = useContext(SubmissionContext);
    const [reviewerId, setReviewerId] = useState('');
    const [assigned, setAssigned] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [review, setReview] = useState(props.review);

    const [assignReviewer] = useMutation(ASSIGN_REVIEWER);
    const [deleteReview] = useMutation(DELETE_REVIEW);

    const handleAssign = async () => {
        try {
            const response = await assignReviewer({
                variables: {
                    reviewerId,
                    submissionId,
                    reviewerNumber: props.idx + 1,
                    stage: props.stage.stage
                }
            });
            const { stages } = response.data.assignReviewer;
            setReview(stages[props.stage.stage - 1].reviews.find(review => review.reviewerNumber === props.idx + 1));
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }
        setTimeout(() => {
            setAssigned(false);
        }, 2000);
    };

    const handleRemove = async () => {
        setLoading(true);
        try {
            await deleteReview({ variables: { id: review.id } });
            setReview(null);
            setReviewerId('');
        } catch (err) {
            const isActive = JSON.stringify(err).includes('non-active');
            if (isActive) {
                errorSnackbar({ text: 'Only applications from the current season may be changed' });
            } else {
                errorSnackbar({ text: 'error' });
            }
        }

        setLoading(false);
        setDialogOpen(false);
    };

    return (
        <div className='Submission__AssignReviewer flex align-baseline'>
            <Heading className='Submission__AssignReviewer__Heading' number={3}>Reviewer {props.idx + 1}</Heading>
            {review ? (
                <>
                    <Heading className='Submission__AssignReviewer__Heading' number={3}>{review.reviewer.name}</Heading>
                    <ApplicationStatus status={review.status} />
                    <Button
                        className='Submission__Button'
                        size='large'
                        type='error'
                        onClick={() => setDialogOpen(true)}
                    >Remove Reviewer
                    </Button>
                    <ConfirmationDialog
                        title='Remove Reviewer?'
                        text={`Are you sure you want to remove the reviewer ${review.reviewer.name} and reset the review?`}
                        confirmText='Remove'
                        open={dialogOpen}
                        onCancel={() => setDialogOpen(false)}
                        onConfirm={handleRemove}
                        loading={loading}
                    />
                </>
            ) : (
                <>
                    <Select
                        className='Submission__AssignReviewer__Select'
                        searchable
                        options={reviewers.map(reviewer => ({ text: reviewer.name || '', value: reviewer.id }))}
                        selectedValue={reviewerId}
                        onChange={({ value }) => setReviewerId(value)}
                    />
                    {assigned && (
                        <div>
                            <Icon name='check' className='StageReviewer__successIcon' />
                            <Body color='success'>Assigned</Body>
                        </div>

                    )}
                    {reviewerId && !assigned && <Button className='Submission__AssignReviewer__Button' type='secondary-outline' onClick={handleAssign}>Assign Reviewer</Button>}
                </>
            )}
        </div>
    );
};

AssignReviewer.propTypes = {
    idx: PropTypes.number,
    review: PropTypes.object,
    stage: PropTypes.object
};

export default AssignReviewer;
