import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContextProvider';
import Table from '../modules/Table';
import Tooltip from '../modules/Tooltip';
import { Body } from '../modules/Typography';
import ApplicationStatus from './ApplicationStatus';
import ApplicationAction from '../stageDetail/ApplicationAction';
import { SubmissionsContext } from '../pages/Submissions';

const ApplicationTable = props => {
    const { role } = useContext(UserContext);
    const { loading, data, offset, limit, setOffset, setLimit } = useContext(SubmissionsContext);
    const navigate = useNavigate();
    const pageSizeOptions = ['25', '50', '75', '100'];
    const submissions = data ? data.submissions : { items: [] };
    const totalItems = submissions?.totalItems || 0;

    if (!loading) {
        var tableData = submissions?.items.map(submission => (
            {
                id: submission.id,
                shortlisted: submission.shortlisted,
                applicant: submission.applicants.map(applicant => applicant.name),
                role: submission.applicants.length ? (submission.applicants[0].type === 'both' ? 'Writer / Director' : `${submission.applicants[0].type}`) : '',
                status: submission.stages.find(stage => stage.reviews.length) ? submission.stages.find(stage => stage.reviews.length).reviews[0].status : 'NOT_STARTED'
            }
        ));
    }

    const handleRowClick = ({ original }) => {
        navigate(`/submissions/${original.id}`);
    };

    const columns = [
        {
            Header: 'Applicant(s)',
            accessor: 'applicant',
            Cell: cell => (
                <div className='Tooltip__revealer'>
                    <Body className='capitalize' number={1} color='primary'>
                        {cell.value[0]}
                        {cell.value.length > 1 && '...'}
                    </Body>
                    {cell.value.length > 1 && (<Tooltip>{cell.value.slice(1).join(', ')}</Tooltip>)}
                </div>
            )
        },
        {
            Header: 'Role',
            accessor: 'role'
        },
        {
            Header: 'Status',
            accessor: 'status',
            Cell: cell => <ApplicationStatus status={cell.value} />
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: cell => <ApplicationAction rowData={cell.row.original} />
        }
    ];

    if (role === ('ADMIN' || 'SUPERADMIN')) { columns.splice(2, 1); } else { columns.pop(); }

    return (
        <Table
            loading={loading}
            columns={columns}
            data={tableData}
            handleRowClick={handleRowClick}
            selectable
            pagination
            pageSizeOptions={pageSizeOptions}
            setLimit={setLimit}
            setOffset={setOffset}
            limit={limit}
            offset={offset}
            totalItems={totalItems}
            pageCount={Math.round(totalItems / limit)}
        />
    );
};

ApplicationTable.propTypes = {};

export default ApplicationTable;
