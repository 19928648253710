import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import Select from './Select';
import Icon from './Icon';
import { Heading } from '../modules/Typography';
import { UserContext } from '../../contexts/UserContextProvider';
import { WidthContext } from '../../contexts/WidthContextProvider';

const Filter = props => {
    const { role: userRole } = useContext(UserContext);
    const { isMobile, size } = useContext(WidthContext);
    const isAdmin = userRole !== 'REVIEWER';

    const {
        role,
        setRole,
        shortlisted,
        setShortlisted,
        status,
        setStatus,
        reviewed,
        setReviewed
    } = props;
    const roleOptions = [
        { text: 'All Roles', value: 'all' },
        { text: 'Director', value: 'director' },
        { text: 'Writer', value: 'writer' },
        { text: 'Writer/Director', value: 'both' }
    ];
    const statusOptions = [
        { text: 'All Statuses', value: null },
        { text: 'Assigned', value: 'NOT_STARTED' },
        { text: 'In Progress', value: 'IN_PROGRESS' },
        { text: 'Completed', value: 'COMPLETED' }
    ];

    const handleSelectChanged = ({ value }) => {
        setRole(value);
    };

    const clearFilter = () => {
        setStatus('COMPLETED');
        setShortlisted(null);
        setReviewed(null);
    };

    const showClearButton = shortlisted || status === 'REJECTED';

    return (
        <>
            <div className={`Filter ${userRole === 'REVIEWER' ? 'flex flex-end' : `${size === 'md' || isMobile ? '' : 'flex'}`}`}>
                {isAdmin && (
                    <div className={`Filter__left ${size === 'md' || isMobile ? '' : 'flex'}`}>
                        <Heading number={4} className='Filter__text' type='heading4'>Filter by</Heading>
                        <div className={`Filter__Buttons ${size === 'md' || isMobile ? '' : 'flex flex-start'}`}>
                            <Button
                                className={`Filter__Buttons__button ${reviewed ? 'active' : ''}`}
                                type='secondary-outline'
                                color='default'
                                size='large'
                                text='Reviewed'
                                onClick={() => setReviewed(reviewed ? null : 'COMPLETED')}
                            />

                            <Button
                                className={`Filter__Buttons__button ${status === 'REJECTED' ? 'active' : ''}`}
                                type='secondary-outline'
                                size='large'
                                text='Rejected'
                                onClick={() => { setStatus(status === 'COMPLETED' ? 'REJECTED' : 'COMPLETED'); }}
                            />
                            <Button
                                className={`Filter__Buttons__button Filter__Shortlist ${shortlisted ? 'active' : ''}`}
                                type='secondary-outline'
                                size='large'
                                text='Shortlisted'
                                onClick={() => setShortlisted(!shortlisted)}
                            />
                            {showClearButton && (
                                <Button className={`Filter__Close ${size === 'md' || isMobile ? 'stacked' : ''}`} type='tertiary' size='small' onClick={clearFilter}>
                                    <Icon name='close' />
                                </Button>
                            )}
                        </div>
                    </div>
                )}
                <div className={`Filter__Select ${size === 'md' || isMobile ? '' : 'flex flex-end'}`}>
                    {userRole === 'REVIEWER' && (
                        <div className={`Filter__Select ${size === 'md' || isMobile ? '' : 'small'}`}>
                            <Select
                                className='Filter__Select__comp'
                                selectedValue={reviewed}
                                options={statusOptions}
                                onChange={({ value }) => setReviewed(value)}
                            />
                        </div>
                    )}
                    <div className='Filter__Select'>
                        <Select
                            className='Filter__Select__comp'
                            selectedValue={role}
                            options={roleOptions}
                            onChange={handleSelectChanged}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Filter;

Filter.propTypes = {
    /* text for the selected role of applicants */
    role: PropTypes.string.isRequired,

    /* Function to set the filtering role of applicants */
    setRole: PropTypes.func.isRequired,

    /* Function to set the filtering status of applicants  */
    setStatus: PropTypes.func.isRequired,
    shortlisted: PropTypes.bool,
    status: PropTypes.string,
    reviewed: PropTypes.bool,
    setReviewed: PropTypes.func,

    /* Function to set the filter for shortlisted applicants */
    setShortlisted: PropTypes.func.isRequired
};

Filter.defaultProps = {};
