import React, { useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { GET_SEASONS } from '../../queries';
import { useQuery } from '@apollo/client';
import { UserContext } from '../../contexts/UserContextProvider';
import { WidthContext } from '../../contexts/WidthContextProvider';
import Select from '../modules/Select';
import logo from '../../images/LAUNCHPAD_LOGO_wht_NoTagline2.svg';
import IconButton from '../modules/IconButton';
import Icon from '../modules/Icon';
import Loading from '../modules/Loading';
import { Heading, Subtitle } from '../modules/Typography';
import { LayoutContext } from './Layout';

const TopNav = props => {
    const cookies = new Cookies();
    const { data, loading } = useQuery(GET_SEASONS);
    const { setUser, setToken, role } = useContext(UserContext);
    const { season, setSeason } = useContext(UserContext);
    const { isMobile } = useContext(WidthContext);
    const { setSideNavOpen, sideNavOpen } = useContext(LayoutContext);
    const [menuOpen, setMenuOpen] = useState(false);
    const seasons = data?.seasons;
    const currentActiveSeason = seasons?.filter(season => season.activeSeason);
    const currentSeason = currentActiveSeason ? currentActiveSeason[0].seasonNumber : '';
    const isAdmin = role !== 'REVIEWER';

    const handleSignOut = () => {
        cookies.remove('access_token');
        cookies.remove('refresh_token');
        setUser(null);
        setToken(null);
    };

    const handleSelectChanged = ({ value }) => {
        setSeason(value);
    };

    const seasonOptions = seasons?.map((item) => ({ text: `Season ${item?.seasonNumber}`, value: item?.id })
    );

    if (loading) return <Loading />;

    return (
        <div className='TopNav'>
            <div className='nav-wrapper flex'>
                <div>
                    <div className='burger-container' />
                    <IconButton disney name='menu' onClick={() => setSideNavOpen(!sideNavOpen)} />
                    <img src={logo} className='TopNav__logo' />
                </div>
                <div className='TopNav__RightMenu'>
                    {isAdmin && (
                        <div className='TopNav__Select'>
                            <Select
                                className='TopNav__Select__button'
                                selectedValue={season}
                                options={seasonOptions}
                                placeholder={`Season ${'' || currentSeason}`}
                                onChange={handleSelectChanged}
                            />
                        </div>)}
                    <div className='TopNav__settings pointer' onClick={() => setMenuOpen(!menuOpen)}>
                        {!isMobile &&
                            <>
                                <Heading number={4} className='white-text page-settings'>Settings</Heading>
                            </>}
                        <Icon name='caret-down' className='pointer' />
                        {menuOpen && (

                            <div className='TopNav__sign-out pointer' onClick={handleSignOut}>
                                <div className='topbar__link'>
                                    <Icon name='logout' />
                                    <Subtitle className='sign-out' number={2}>Log Out</Subtitle>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TopNav;
