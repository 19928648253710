import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { ADD_SEASON } from '../../queries';
import DialogWrapper from '../modules/DialogWrapper';

const AddSeason = props => {
    const [loading, setLoading] = useState(false);
    const [addSeason] = useMutation(ADD_SEASON);

    const currentYear = new Date().getFullYear();

    const handleConfirm = async () => {
        setLoading(true);
        await addSeason({
            variables: {
                year: currentYear,
                activeSeason: true
            }
        });
        props.getSeasons();
        setLoading(false);
        props.setOpen(false);
    };

    const handleCancel = () => {
        props.setOpen(false);
    };

    return (
        <DialogWrapper
            open={props.open}
            padded
            closeOnEscape
            title='Add New Season'
            subtitle='Are you sure you want to create a new active season? This action cannot be reversed and will deactivate the current season reviewers.'
            confirmText='Confirm'
            className='InviteReviewers'
            loading={loading}
            heightSize='small'
            size='small'
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        />
    );
};

AddSeason.propTypes = {
    type: PropTypes.string,
    role: PropTypes.string,
    setOpen: PropTypes.func,
    inviteSent: PropTypes.bool,
    setInviteSent: PropTypes.func,
    open: PropTypes.bool,
    getSeasons: PropTypes.func
};

export default AddSeason;
