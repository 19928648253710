import React, { useContext } from 'react';
import { DashboardContext } from '../pages/Dashboard';
import DashboardCardSet from './DashboardCardSet';

const DashboardCards = props => {
    const { applicationRoles, applicationTotalStats, applicationStatsByStage } = useContext(DashboardContext);

    return (
        <div className='Dashboard__Cards flex wrap'>
            <DashboardCardSet
                title='Application Roles'
                cards={[
                    {
                        name: 'Directors',
                        number: applicationRoles?.directors
                    },
                    {
                        name: 'Writers',
                        number: applicationRoles?.writers
                    },
                    {
                        name: 'Writer / Directors',
                        number: applicationRoles?.both
                    }
                ]}
            />
            <DashboardCardSet
                title='Application Stats'
                cards={[
                    {
                        name: 'Applications Started',
                        number: applicationTotalStats ? applicationTotalStats.started : 0,
                        color: 'purple'
                    },
                    {
                        name: 'Applications Completed',
                        number: applicationTotalStats ? applicationTotalStats.completed : 0,
                        color: 'green'
                    },
                    {
                        name: 'Applications Reviewed',
                        number: applicationTotalStats ? applicationTotalStats.reviewed : 0,
                        color: 'green'
                    }
                ]}
            />
            {applicationStatsByStage?.map(stage => (
                <DashboardCardSet
                    key={`Cardset-${stage.stage}`}
                    title={`Stage ${stage.stage} Application Stats`}
                    cards={[
                        {
                            name: 'Applications',
                            number: stage.totalApplications,
                            color: 'green'
                        },
                        {
                            name: 'Applications Reviewed',
                            number: stage.totalReviewed,
                            color: 'green'
                        }
                    ]}
                />
            ))}
        </div>
    );
};

export default DashboardCards;
