import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { GET_SEASONS, UPDATE_SEASON } from '../../queries';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { UserContext } from '../../contexts/UserContextProvider';
import Table from '../modules/Table';
import AddSeason from '../manageReviewers/AddSeason';
import ActivateSeason from '../manageReviewers/ActivateSeason';
import Loading from '../modules/Loading';
import Button from '../modules/Button';
import { Container } from '../modules/Grid';

const ManageSeasons = props => {
    const [getSeasons, { data, loading }] = useLazyQuery(GET_SEASONS);
    const { activeSeason } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [activeOpen, setActiveOpen] = useState(false);
    const [seasonOpen, setSeasonOpen] = useState('');

    useEffect(() => {
        getSeasons();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            getSeasons();
        }, 500);
    }, [activeSeason]);

    var tableData = data?.seasons.map(item => (
        {
            activeSeason: item.activeSeason ? 'Active' : 'Inactive',
            id: item.id,
            seasonNumber: `Season ${item?.seasonNumber}`,
            year: item?.year,
            changeSeason: item.activeSeason !== true
        }
    ));

    const handleActiveSeason = (id) => {
        setActiveOpen(true);
        setSeasonOpen(id);
    };

    const columns = [
        {
            Header: 'Season',
            accessor: 'seasonNumber'
        },
        {
            Header: 'Year',
            accessor: 'year'
        },
        {
            Header: 'Status',
            accessor: 'activeSeason'
        },
        {
            Header: 'Activate Season',
            accessor: 'changeSeason',
            Cell: cell => cell.value ? <Button type='secondary-outline' color='default' size='small' text='Activate Season' onClick={() => { handleActiveSeason(cell.row.original.id); }} /> : ''

        }
    ];

    if (loading) return <Loading />;

    return (

        <div className='Exports'>
            <Helmet><title>Launchpad Back Office | Manage Seasons</title></Helmet>
            <Container>
                <Button icon='plus' color='white' onClick={setOpen}>Add New Season</Button>
                <br />
                <br />
                <AddSeason open={open} setOpen={setOpen} getSeasons={getSeasons} />
                <ActivateSeason activeOpen={activeOpen} setActiveOpen={setActiveOpen} activatedSeason={seasonOpen} getSeasons={getSeasons} />
                <Table
                    loading={loading}
                    columns={columns}
                    data={tableData}
                    pagination={false}
                />
            </Container>
        </div>
    );
};

export default ManageSeasons;
