import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UPDATE_NOTE, DELETE_REVIEW } from '../../queries';
import { SubmissionContext } from '../pages/Submission';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import Icon from '../modules/Icon';
import IconButton from '../modules/IconButton';
import TextArea from '../modules/TextArea';
import Radio from '../modules/Radio';
import Button from '../modules/Button';
import ConfirmationDialog from '../modules/ConfirmationDialog';
import SubmissionReview from './SubmissionReview';
import AssignReviewer from './AssignReviewer';
import SubmissionOverallScoreCard from './SubmissionOverallScoreCard';
import { WidthContext } from '../../contexts/WidthContextProvider';

export const AdminReviewContext = React.createContext();

const AdminStage = props => {
    const { allExpanded, submissionId, refetch } = useContext(SubmissionContext);
    const { documentWidth } = useContext(WidthContext);
    const { stage } = props;
    const [open, setOpen] = useState(true);
    const [recommended, setRecommended] = useState(null);
    const [notes, setNote] = useState(stage.notes || '');
    const [savedIcon, setSavedIcon] = useState(false);
    const [buttonActive, setButtonActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [name, setName] = useState('');
    const [id, setId] = useState('');

    const [updateNote] = useMutation(UPDATE_NOTE);
    const [deleteReview] = useMutation(DELETE_REVIEW);

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    // const handleRecommended = (value) => {

    // };

    const handleNote = () => {
        setSavedIcon(true);
        updateNote({
            variables: {
                id: submissionId,
                stage: stage.stage,
                note: notes
            }
        });
        setTimeout(function () { setSavedIcon(false); }, 3000);
        setButtonActive(false);
    };

    const handleSaveNote = (e) => {
        setNote(e.target.value);
        setButtonActive(true);
    };

    const handleRemove = async (reviewId) => {
        setLoading(true);
        await deleteReview({ variables: { id: reviewId } });
        refetch();
        setLoading(false);
        setDialogOpen(false);
    };

    const handleButtonTapped = (review) => {
        setDialogOpen(true);
        setName(review.reviewer.name);
        setId(review.id);
    };

    const TOTAL_REVIEWERS = [0, 3, 3, 1, 1];

    const columns = documentWidth < 1180 ? 12 : 6;
    const offset = documentWidth < 1180 ? 0 : 3;

    const reviews = Array.from(
        new Array(TOTAL_REVIEWERS[stage.stage]),
        (el, index) => {
            const review = stage.reviews.find(rev => rev.reviewerNumber === index + 1);
            return (
                review && review.status === 'COMPLETED' ? (
                    <div key={`completed-${stage.stage}-${index}`}>
                        <div className='Submission__AssignReviewer flex align-baseline'>
                            <Heading className='Submission__Stage__title' block number={3}>Reviewer {index + 1}</Heading>
                            <Heading className='Submission__Stage__reviewer' block number={3}>{review.reviewer.name}</Heading>
                            <Button
                                className='Submission__Button'
                                size='large'
                                type='error'
                                onClick={() => handleButtonTapped(review)}
                            >Remove Reviewer
                            </Button>
                        </div>
                        {review.sections.map((section, idx) => (
                            <>
                                <SubmissionReview key={`section-${idx}`} review={section} readOnly />
                            </>
                        ))}
                        <Row>
                            <Col xs={12} lg={columns} lgOffset={offset}>
                                <SubmissionOverallScoreCard isAdmin overallScore={review.overallScore} />
                            </Col>
                        </Row>
                    </div>
                ) : <AssignReviewer key={`assign-${stage.stage}-${index}`} idx={index} stage={stage} review={review} />
            );
        });

    return (
        <>
            <Row>
                <Col xs={12} className='flex Submission__Reviews__Block'>
                    <Heading number={2} block className='Submission__Reviews__Subtitle capitalize'>Stage {stage.stage}</Heading>
                    <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                </Col>
            </Row>
            {open && (
                <>
                    <Row>
                        <Col xs={12} className='flex'>
                            <Heading number={3} block className='Submission__Reviews__Subtitle capitalize'>Notes</Heading>
                        </Col>
                    </Row>
                    {open && (
                        <>
                            <Row className='Submission__Notes__container'>
                                <Col md={8} className='flex align-flex-end'>
                                    <TextArea
                                        className='Submission__Notes'
                                        value={notes}
                                        onChange={handleSaveNote}
                                        padded={false}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <div className='flex align-flex-end Submission__Notes__button'>
                                    <Button className={buttonActive ? 'enabled' : 'disabled'} type='primary' onClick={handleNote}>Save Notes</Button>
                                    {savedIcon &&
                                    <Col className='Submission__Notes__icon'><Icon size='small' name='check' className='StageReviewer__successIcon' />
                                        <Body className='Submission__Notes__icon'>Saved</Body>
                                    </Col>}
                                </div>
                            </Row>
                        </>
                    )}
                    {reviews}
                    {stage.stage === 3 && (
                        <Row>
                            <Col sm={8} className=' ScoreCard SubmissionOverallScoreCard SubmissionOverallScoreCard__Section flex'>
                                <Body className='SubmissionOverallScoreCard__Section__Name'>Overall Recommendation</Body>
                                <div className='SubmissionOverallScoreCard__Radios flex'>
                                    <Radio
                                        padded={false}
                                        selected={recommended}
                                        name='Yes'
                                        value='true'
                                        handleChange={() => setRecommended(true)}
                                    />
                                    <Radio
                                        padded={false}
                                        selected={recommended === false}
                                        name='No'
                                        value='false'
                                        handleChange={() => setRecommended(false)}
                                    />
                                </div>

                            </Col>
                        </Row>
                    )}
                </>
            )}
            <ConfirmationDialog
                title='Remove Reviewer?'
                text={`Are you sure you want to remove the reviewer ${name} and reset the review?`}
                confirmText='Remove'
                open={dialogOpen}
                onCancel={() => setDialogOpen(false)}
                onConfirm={() => handleRemove(id)}
                loading={loading}
            />
        </>
    );
};

AdminStage.propTypes = {
    stage: PropTypes.object
};
export default AdminStage;
