import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { UserContext } from '../../contexts/UserContextProvider';
import { UPDATE_SEASON } from '../../queries';
import DialogWrapper from '../modules/DialogWrapper';

const ActivateSeason = props => {
    const [loading, setLoading] = useState(false);
    const { setActiveSeason } = useContext(UserContext);
    const [updateSeason] = useMutation(UPDATE_SEASON);
    const id = props.activatedSeason;

    const handleConfirm = async () => {
        setLoading(true);
        try {
            updateSeason({ variables: { id, activeSeason: true } });
            setActiveSeason(id);
            props.getSeasons();
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
        props.setActiveOpen(false);
    };

    const handleCancel = () => {
        props.setActiveOpen(false);
    };

    return (
        <DialogWrapper
            open={props.activeOpen}
            padded
            closeOnEscape
            title='Activate New Season'
            subtitle='Are you sure you want to activate a new season? This action will deactivate the current season reviewers and cannot be undone.'
            confirmText='Confirm'
            className='InviteReviewers'
            loading={loading}
            heightSize='small'
            size='small'
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        />
    );
};

ActivateSeason.propTypes = {
    type: PropTypes.string,
    role: PropTypes.string,
    setActiveOpen: PropTypes.func,
    activeOpen: PropTypes.bool,
    activatedSeason: PropTypes.func,
    getSeasons: PropTypes.func

};

export default ActivateSeason;
