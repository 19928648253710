import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from '../modules/Typography';
import Table from '../modules/Table';

const DashboardTable = props => {
    return (
        <div className='Dashboard__Table'>
            <Heading number={2} block className='Dashboard__Table__title'>{props.title}</Heading>
            <Table
                columns={props.columns}
                data={props.data}
                pagination={false}
            />
        </div>

    );
};

DashboardTable.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    data: PropTypes.array
};

export default DashboardTable;
