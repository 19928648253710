import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Body } from '../modules/Typography';
import { LoadingIcon } from '../modules/Loading';

const ScoreNumber = props => {
    switch (props.score) {
    case null:
        if (props.canEdit) {
            return (
                <div className={`ScoreCard__Button ${props.canEdit ? 'pointer' : ''} ScoreCard__Button--add `}>
                    <Body className='ScoreCard__Button__Icon'>+</Body>
                </div>
            );
        }
        return (
            <div className={`ScoreCard__Button ${props.canEdit ? 'pointer' : ''} ScoreCard__Button--none `}>
                <Body className='ScoreCard__Button__Icon ScoreCard__Button__Icon--none'>N/A</Body>
            </div>
        );

    case -1:
        return (
            <div className={`ScoreCard__Button ${props.canEdit ? 'pointer' : ''} ScoreCard__Button--none `}>
                <Body className='ScoreCard__Button__Icon ScoreCard__Button__Icon--none'>N/A</Body>
            </div>
        );
    default:
        return (
            <div className={`ScoreCard__Button ${props.canEdit ? 'pointer' : ''}  ScoreCard__Button__number__${Math.floor(props.score)}`}>
                <Body className='ScoreCard__Button__Icon ScoreCard__Button__number ScoreCard__Button__Icon'>{props.score}</Body>
            </div>
        );
    }
};

const ScoreButton = props => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        if (props.canEdit) {
            setOpen(!open);
        }
    };

    const handleChange = async (num) => {
        setLoading(true);
        await props.handleScore({ id: props.id, value: num });
        setLoading(false);
        setOpen(false);
    };

    return (
        <div className={`ScoreCard__Score ${open ? 'ScoreCard__Score--open' : ''}`}>
            {loading ? <LoadingIcon /> : (
                <>
                    <div className='flex flex-start' onClick={handleClick}>
                        <ScoreNumber score={props.score} loading={loading} canEdit={props.canEdit} />
                        <Heading className='ScoreCard__Score__Text' number={4}>{props.text}</Heading>
                    </div>
                    {open && (
                        <div className=' ScoreCard__Score__Options flex'>
                            {[-1, 0, 1, 2, 3, 4, 5].map(num => (
                                <div key={`score-${num}`} className='pointer' onClick={() => handleChange(num)}>
                                    <ScoreNumber score={num} />
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>

    );
};

ScoreNumber.propTypes = {
    canEdit: PropTypes.bool,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

ScoreButton.propTypes = {
    id: PropTypes.string,
    score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    canEdit: PropTypes.bool,
    handleScore: PropTypes.func,
    text: PropTypes.string
};
export default ScoreButton;
