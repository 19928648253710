import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTable, usePagination, useFlexLayout, useSortBy } from 'react-table';
import IconButton from './IconButton';
import Select from './Select';
import Loading from './Loading';

const Table = props => {
    const [clicked, setClicked] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [points, setPoints] = useState({
        x: 0,
        y: 0
    });

    const columns = useMemo(() => (props.columns), [props.columns]);

    const data = useMemo(() => (props.data), [props.data]);

    const options = {
        columns,
        data,
        initialState: { pageIndex: 0 },
        manualPagination: props.pagination,
        manualSortBy: true,
        pageCount: props.pageCount
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        options,
        useFlexLayout,
        useSortBy,
        usePagination
    );

    const handlePageSizeChanged = ({ value }) => {
        if (props.pagination) {
            props.setLimit(+value);
            props.setOffset(0);
        }
    };

    const handleRightClick = ({ id }) => {
        window.open(`/submissions/${id}`);
    };

    useEffect(() => {
        const handleClick = () => setClicked(false);
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const lastPageOffset = props.pagination ? props.totalItems - (props.totalItems % props.limit) : 0;
    return (
        <>
            {props.loading ? (
                <>
                    <table {...getTableProps()} className='Table'>
                        <thead>
                            {headerGroups.map((headerGroup, idx) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={`headergroup-${idx}`}>
                                    {headerGroup.headers.map((column, idx) => (
                                        <th
                                            key={`header-${idx}`}
                                            className='Typography Typography--caption'
                                            {...column.getHeaderProps()}
                                        >
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                    </table>
                    <Loading />
                </>
            ) : (
                <table {...getTableProps()} className='Table'>
                    <thead className='block'>
                        {headerGroups.map((headerGroup, idx) => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={`headergroup-${idx}`}>
                                {headerGroup.headers.map((column, idx) => (
                                    <th
                                        key={`header-${idx}`}
                                        className='Typography Typography--caption'
                                        {...column.getHeaderProps()}
                                    >
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody className='block' {...getTableBodyProps()}>
                        {rows.map((row, idx) => {
                            prepareRow(row);
                            return (
                                <tr
                                    {...row.getRowProps()} className={props.selectable ? 'pointer' : ''}
                                    onContextMenu={(e) => {
                                        if (props.isApplicationTable) {
                                            e.preventDefault();
                                            setSelectedRow(row.original);
                                            setClicked(true);
                                            setPoints({
                                                x: e.pageX,
                                                y: e.pageY
                                            });
                                        }
                                    }}
                                    key={`row-${idx}`} onClick={props.selectable ? () => props.handleRowClick(row) : null}
                                >
                                    {row.cells.map((cell, idx) => {
                                        return (
                                            <td
                                                key={`cell-${idx}`}
                                                className={cell.column.className || 'Typography Typography--body1 capitalize'}
                                                {...cell.getCellProps()}

                                            >
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>

                </table>
            )}
            {props.pagination && (
                <div className='Table__footer'>
                    <span className='Table__footer__pagination__text'>
                        Rows per page:{' '}
                    </span>
                    <Select
                        className='Table__footer__pagination__select'
                        placeholder={`${props.limit}`}
                        value={`${props.limit}`}
                        options={props.pageSizeOptions.map(pageSize => ({ text: pageSize, value: pageSize }))}
                        onChange={handlePageSizeChanged}
                    />
                    <span className='Table__footer__pagination__text'>
                        {props.offset + 1} - {(props.offset + props.limit < props.totalItems) ? (props.offset + props.limit) : props.totalItems} of {props.totalItems}
                    </span>
                    <IconButton
                        dsm
                        styleProps={{ fontSize: '1.20rem' }}
                        name='first'
                        size='small'
                        className='Table__footer__pagination__first'
                        onClick={() => { props.setOffset(0); }}
                        disabled={props.offset === 0}
                    />
                    <IconButton
                        name='caret-left'
                        size='small'
                        className='Table__footer__pagination__previous'
                        onClick={() => { props.setOffset(props.offset - props.limit); }}
                        disabled={props.offset === 0}
                    />
                    <IconButton
                        name='caret-right'
                        size='small'
                        className='Table__footer__pagination__next'
                        onClick={() => { props.setOffset(props.offset + props.limit); }}
                        disabled={props.offset + props.limit >= props.totalItems}
                    />
                    <IconButton
                        name='last'
                        dsm
                        styleProps={{ fontSize: '1.20rem' }}
                        size='small'
                        className='Table__footer__pagination__last'
                        onClick={() => { props.setOffset(lastPageOffset === props.totalItems ? props.totalItems - props.limit : lastPageOffset); }}
                        disabled={props.offset + props.limit >= props.totalItems}
                    />
                </div>
            )}
            {clicked && (
                <div className='ContextMenu' style={{ top: points.y - 180, left: points.x - 235 }}>
                    <ul>
                        <li onClick={() => handleRightClick(selectedRow)}>Open in a new tab</li>
                    </ul>
                </div>
            )}

        </>
    );
};

export default Table;

Table.propTypes = {
    // className to pass down to tags
    columns: PropTypes.array,
    data: PropTypes.array,
    handleRowClick: PropTypes.func,
    pagination: PropTypes.bool,
    limit: PropTypes.number,
    setLimit: PropTypes.func,
    offset: PropTypes.number,
    setOffset: PropTypes.func,
    totalItems: PropTypes.number,
    pageCount: PropTypes.number,
    pageSizeOptions: PropTypes.array,
    selectable: PropTypes.bool,
    loading: PropTypes.bool
};

Table.defaultProps = {
    selectable: false,
    className: '',
    hasBorder: true,
    columns: [],
    data: [],
    pagination: true,
    pageSizeOptions: [],
    totalItems: 0
};
