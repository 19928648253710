import { gql } from '@apollo/client';

export const SYNC_REVIEWER = gql`
  mutation syncReviewer {
    syncReviewer {
      id
      firstName
      lastName
      name
      email
      role
    }
  }
`;

// SYNCREVIEWER

export const SEND_INVITES = gql`
mutation sendInvites($emails: [String]!, $role: Role!, $provider: AuthType! ) {
  sendInvites(invite: {
    emails: $emails,
    role: $role,
    authType: $provider
  }) {
    message
  }
}
`;

export const RESEND_INVITE = gql`
mutation resendInvite($id: String!) {
  resendInvite (id: $id) {
    message
  }
} 
`;
