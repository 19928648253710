import { gql } from '@apollo/client';

export const GET_DASHBOARD = gql`
  query getDashboard($seasonId: String) {
    metrics(seasonId: $seasonId) {
     applicationRoles {
        directors
        writers
        both
      }
      applicationTotalStats {
        started
        completed
        reviewed
      }
      applicationStatsByStage {
        stage
        totalApplications
        totalReviewed
      }
      applicationStepsCompleted {
        step
        completed
      }
      reviewsCompletedByReviewer {
        stage
        reviewerDetails {
          email
          completed
        }
      }
      applicantsByState {
        state
        totalApplicants
      }
      applicantsByHeard {
        heard
        totalApplicants
      }
      applicantsByRace {
        race
        totalApplicants
      }
      applicantsByGender {
        gender
        totalApplicants
      }
      applicantsByEthnicity {
        ethnicity
        totalApplicants
      }
      applicantsByVeteranStatus {
        veteranStatus
        totalApplicants
      }
      applicantsByDisability {
        disability
        totalApplicants
      }
      applicantsEmployedByDisney{
        employedByDisney
        totalApplicants
      }
    }
  }
`;
