import React, { useState, useContext } from 'react';
import Select from '../modules/Select';
import Box from '../modules/Box';
import ReviewerTable from '../manageReviewers/ReviewerTable';
import { UserContext } from '../../contexts/UserContextProvider';
import InviteReviewers from '../manageReviewers/InviteReviewers';
import { Heading } from '../modules/Typography';

const ManageReviewers = props => {
    const { role: userRole } = useContext(UserContext);
    const [role, setRole] = useState('');
    const [type, setType] = useState('');
    const [open, setOpen] = useState(false);
    const [inviteSent, setInviteSent] = useState(false);

    const reviewerOptions = [
        { text: 'MyID', value: 'myid' },
        { text: 'Email', value: 'email' }
    ];

    const handleSelectClick = ({ value, id }) => {
        setRole(id);
        setType(value);
        setOpen(true);
    };

    return (
        <Box borderTyper='dashed' type='white' padded={false} className='ManageReviewers__box'>
            <InviteReviewers open={open} type={type} role={role} setOpen={setOpen} setInviteSent={setInviteSent} inviteSent={inviteSent} />
            <div className='ManageReviewers__header'>
                <Heading number={4} className='ManageReviewers__header__text' type='heading4'>Reviewers</Heading>

                <div className='ManageReviewers__header__select'>
                    <Select
                        placeholder='Invite Reviewer'
                        className='ManageReviewers__header__reviewer'
                        id='REVIEWER'
                        options={reviewerOptions}
                        onChange={handleSelectClick}
                    />
                    <Select
                        placeholder='Invite Admin'
                        className='ManageReviewers__header__admin'
                        id='ADMIN'
                        options={reviewerOptions}
                        onChange={handleSelectClick}
                    />
                    {userRole === 'SUPERADMIN' &&
                    <Select
                        placeholder='Invite Super Admin'
                        className='ManageReviewers__header__admin'
                        id='SUPERADMIN'
                        options={reviewerOptions}
                        onChange={handleSelectClick}
                    />}

                </div>
            </div>
            <div className='ManageReviewers__table'>
                <ReviewerTable inviteSent={inviteSent} />
            </div>
        </Box>
    );
};

ManageReviewers.propTypes = {

};

export default ManageReviewers;
