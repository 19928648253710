import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SubmissionContext } from '../pages/Submission';
import { Heading, Body } from '../modules/Typography';
import { Row, Col } from '../modules/Grid';
import IconButton from '../modules/IconButton';

const SubmissionDirectingSample = props => {
    const { genres, sampleTypes, allExpanded } = useContext(SubmissionContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setOpen(allExpanded);
    }, [allExpanded]);

    const { name, params, url, password } = props.sample;
    const title = params.find(param => param.key === 'title').value;
    const writer = params.find(param => param.key === 'writer').value;
    const director = params.find(param => param.key === 'director').value;
    const producer = params.find(param => param.key === 'producer').value;
    const logLine = params.find(param => param.key === 'logLine').value;
    const synopsis = params.find(param => param.key === 'synopsis').value;
    const year = params.find(param => param.key === 'year').value;
    const awards = params.find(param => param.key === 'awards').value;
    const runningTime = params.find(param => param.key === 'runningTime').value;
    const genre = params.find(param => param.key === 'genre').value;
    const sampleType = params.find(param => param.key === 'sampleType').value;
    const genreText = genres.find(g => g.value === genre)?.text || '';
    const typeText = sampleTypes.find(g => g.value === sampleType)?.text || '';
    const httpUrl = !url.includes('http') ? `http://${url}` : url;

    return (
        <div className='Submission__Applicant'>
            <Row>
                <Col xs={12} className='flex'>
                    <Heading number={2} className='capitalize'>{name}</Heading>
                    <IconButton name={open ? 'caret-down' : 'caret-right'} onClick={() => setOpen(!open)} />
                </Col>
            </Row>
            {open && (
                <>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Youtube/Vimeo Link</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <a href={httpUrl} target='_blank' rel='noreferrer'>
                                <Body color='primary-light'>{httpUrl}</Body>
                            </a>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Password</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{password}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Sample type</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{typeText}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Sample genre</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{genreText}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Title</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{title}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Writer</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{writer}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Director</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{director}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Producer</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{producer}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Logline</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{logLine}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Synopsis</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{synopsis}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Running Time</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{runningTime}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Year Created</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{year}</Body>
                        </Col>
                    </Row>
                    <Row className='spacing-sm'>
                        <Col xs={12} sm={3}>
                            <Heading number={4} block>Awards</Heading>
                        </Col>
                        <Col xs={12} sm={9}>
                            <Body>{awards || 'N/A'}</Body>
                        </Col>
                    </Row>

                </>
            )}

            <hr className='Submission__divider' />

        </div>

    );
};

SubmissionDirectingSample.propTypes = {
    sample: PropTypes.object
};

export default SubmissionDirectingSample;
