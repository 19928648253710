import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Heading, Subtitle, Caption } from '../modules/Typography';
import logo from '../../images/LAUNCHPAD_LOGO_wht_NoTagline2.svg';
import Button from '../modules/Button';
import TextField from '../modules/TextField';
import ConfirmationDialog from '../modules/ConfirmationDialog';
import fetch from '../../utils/fetch';

const Registration = props => {
    const { sentryToken } = useParams();
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errors, setErrors] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (password.length < 6) {
            setErrorMessage('Your password must be at least 6 characters.');
            setErrors(['password']);
        } else if (!/[A-Z]/.test(password)) {
            setErrorMessage('Your password must have uppercase characters.');
            setErrors(['password']);
        } else if (!/[a-z]/.test(password)) {
            setErrorMessage('Your password must have lowercase characters.');
            setErrors(['password']);
        } else if (!/\d/.test(password)) {
            setErrorMessage('Your password must have numeric characters.');
            setErrors(['password']);
        } else {
            setErrors([]);
            setErrorMessage('');
            const { message } = await fetch('/auth/sentry/signup', {
                method: 'POST',
                body: {
                    password,
                    givenName: firstName,
                    familyName: lastName,
                    token: sentryToken
                }
            });
            if (message) {
                if (message === 'existing account') {
                    setErrorMessage('A user with that email already exists.');
                    setErrors(['email']);
                } else if (message.includes('Member must have length') || message.includes('not long enough')) {
                    setErrorMessage('Your password must be at least 6 characters.');
                    setErrors(['password']);
                } else if (message.includes('uppercase characters')) {
                    setErrorMessage('Your password must have uppercase characters.');
                    setErrors(['password']);
                } else if (message.includes('numeric characters')) {
                    setErrorMessage('Your password must have numeric characters.');
                    setErrors(['password']);
                } else {
                    setErrorMessage('There was an error creating this user.');
                    setErrors(['password']);
                }
            } else {
                // setUser(account);
                // setToken({ accessToken: token.access_token, refreshToken: token.refresh_token });
                setConfirmOpen(true);
            }
        }
    };

    const handleConfirm = () => {
        navigate('/login');
    };

    return (
        <div className='layout'>
            <div className='header'>
                <div className='TopNav'>
                    <div className='nav-wrapper flex'>
                        <img src={logo} className='TopNav__logo' />
                    </div>
                </div>
            </div>
            <div className='SideNav__content__container flex align-flex-start'>
                <div className='main-content Registration__container'>
                    <div className='Registration__content flex align-flex-start'>
                        <div className='Registration__half'>
                            <Heading block number={3} className='Registration__title'>Complete Registration</Heading>
                            <Subtitle block number={4}>You've been invited to review applicants for the Launchpad program!</Subtitle>
                            <br />
                            <Subtitle block number={4}>Please fill out the form to complete your registration.</Subtitle>
                        </div>
                        <div className='divider' />
                        <div className='Registration__half'>
                            <form>
                                <TextField
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)}
                                    label='First name'
                                />
                                <TextField
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    label='Last name'
                                />
                                <TextField
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    label='Password' type='password'
                                    error={errors.includes('password')}
                                    errorMessage={errorMessage}
                                />
                                <div className='Registration__passwordCheck'>
                                    <Caption block>Password Requirements</Caption>
                                    <Caption block number={2}>- At least 6 characters</Caption>
                                    <Caption block number={2}>- At least 1 uppercase letter</Caption>
                                    <Caption block number={2}>- At least 1 lowercase letter</Caption>
                                    <Caption block number={2}>- At least 1 number</Caption>
                                </div>
                                <Button onClick={handleSubmit} type='primary' disabled={!firstName || !lastName || !password}>Complete Registration</Button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationDialog
                open={confirmOpen}
                title='Registration Completed'
                text='Thanks for completing your registration!'
                confirmText='Continue to Login'
                onConfirm={handleConfirm}
            />
        </div>
    );
};

export default Registration;
