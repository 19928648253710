import React, { useEffect } from 'react';
import IconButton from './IconButton';
import PropTypes from 'prop-types';
import { Heading, Subtitle } from './Typography';
import DialogFooter from './DialogFooter';

const DialogWrapper = props => {
    const { open, closeOnEscape, onCancel, disableCloseOnClick } = props;

    useEffect(() => {
        if (!closeOnEscape) return;

        const closeWithKeyPress = e => {
            if (e.key === 'Escape') onCancel();
        };

        if (open) window.addEventListener('keyup', closeWithKeyPress);
        return () => window.removeEventListener('keyup', closeWithKeyPress);
    }, [open, closeOnEscape, onCancel]);

    useEffect(() => {
        if (open) {
            window.document.body.style.overflow = 'hidden';
        } else {
            window.document.body.style.overflow = null;
        }
    }, [open]);

    const handleBackgroundClick = () => {
        if (!disableCloseOnClick) {
            props.onCancel();
        }
    };

    return (
        <div className={`DialogWrapper ${props.open ? 'DialogWrapper--open' : ''} ${props.className}`}>
            {props.hasBackgroundTint && <div className='DialogWrapper__background' onClick={handleBackgroundClick} />}

            <div className={`DialogWrapper__box DialogWrapper__box--${props.size} DialogWrapper__box--height-${props.heightSize}`}>
                {(props.title || props.handleBack) && (
                    <div className='DialogWrapper__box__header'>
                        {props.handleBack && <IconButton name='arrow-std-left' onClick={props.handleBack} className='DialogWrapper__box__header__back' />}
                        <Heading number={3}>{props.title}</Heading>
                    </div>
                )}
                {props.subtitle && <Subtitle number={1} className='DialogWrapper__box__subtitle'>{props.subtitle}</Subtitle>}
                <div className={`DialogWrapper__box__body ${props.padded ? 'DialogWrapper__box__body--padded' : ''}`}>
                    {props.children}
                </div>

                {(!props.hideFooter && (props.onConfirm || props.onCancel)) && (
                    <DialogFooter
                        disabled={props.disabled}
                        confirmCancelText={props.confirmCancelText}
                        onCancel={props.onCancel}
                        confirmText={props.confirmText}
                        onConfirm={props.onConfirm}
                        loading={props.loading}
                    />
                )}
            </div>
        </div>
    );
};

export default DialogWrapper;

DialogWrapper.propTypes = {
    // className to pass down to tags
    className: PropTypes.string,

    // title of the dialog
    title: PropTypes.string,

    // subtitle of the dialog
    subtitle: PropTypes.string,

    // function to "go back" in the dialog
    handleBack: PropTypes.func,

    // function to cancel or close the dialog
    onCancel: PropTypes.func,

    // text of the cancel button
    confirmCancelText: PropTypes.string,

    // function to confirm the dialog
    onConfirm: PropTypes.func,
    disableCloseOnClick: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),

    // text of the confirm button
    confirmText: PropTypes.string,

    // whether or not the dialog is open
    open: PropTypes.bool.isRequired,

    // whether or not there is padding on the sides of the dialog
    padded: PropTypes.bool,

    // whether or not the confirm button is disabled
    disabled: PropTypes.bool,

    // default width of the dialog width
    size: PropTypes.oneOf(['auto', 'small', 'medium', 'large']),

    // default height of the dialog width
    heightSize: PropTypes.oneOf(['auto', 'small', 'medium', 'large']),

    // whether or not to close the dialog when the escape key is pressed
    closeOnEscape: PropTypes.bool,

    // whether or not the dialog has a background tint
    hasBackgroundTint: PropTypes.bool,

    // whether or not to hide the footer
    hideFooter: PropTypes.bool,

    // whether or not the confirm action is loading and should be disabled
    loading: PropTypes.bool
};

DialogWrapper.defaultProps = {
    className: '',
    confirmCancelText: 'Cancel',
    confirmText: 'OK',
    padded: false,
    disabled: false,
    size: 'small',
    heightSize: 'small',
    closeOnEscape: true,
    hasBackgroundTint: true,
    hideFooter: false,
    loading: false
};
