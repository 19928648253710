import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '../components/modules/Snackbar';

export const SnackbarContext = React.createContext();

const SnackbarProvider = ({ children }) => {
    const [snackbar, setSnackbar] = useState({
        snackOpen: false,
        snackText: '',
        snackType: 'success'
    });

    const successSnackbar = ({ text }) => setSnackbar({ snackText: text, snackType: 'success', snackOpen: true });
    const errorSnackbar = ({ text }) => setSnackbar({ snackText: text, snackType: 'error', snackOpen: true });

    const closeSnackBar = () => setSnackbar(state => ({ ...state, snackOpen: false }));

    return (
        <SnackbarContext.Provider value={{ snackbar, successSnackbar, errorSnackbar }}>
            {children}
            <Snackbar
                open={snackbar.snackOpen}
                text={snackbar.snackText}
                type={snackbar.snackType}
                handleClose={closeSnackBar}
            />
        </SnackbarContext.Provider>
    );
};

SnackbarProvider.propTypes = {
    children: PropTypes.object
};

export default SnackbarProvider;
